import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import Fuse from "fuse.js";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Child, PreschoolType } from "src/app/models/entities";
import UpdatedAddressObject from "src/app/models/updated-address-object";
import { RunService } from "src/app/services/run.service";
import { ValidationService } from "src/app/services/validation.service";
import { EditAddressDialogComponent } from "../../shared/edit-address-dialog/edit-address-dialog.component";
import { UpdateBoxComponent } from "./update-box/update-box.component";

const filterColumns = [
  "lastName",
  "firstName",
  "birthday",
  "currentProvider",
  "schoolDistrict",
]

const fuseOptions = {
  threshold: 0.2,
  minMatchCharLength: 3,
  keys: [ ...filterColumns, 'address.street', 'address.postcode' ],
};

export interface DialogData {
  phone: string;
  name: string;
}

@Component({
  selector: "app-new-optimization-table",
  templateUrl: "./new-optimization-table.component.html",
  styleUrls: ["./new-optimization-table.component.scss"],
})
export class NewOptimizationTableComponent implements OnInit, AfterViewInit, OnDestroy {

  text!: string;
  runId: number;
  optimizationName = "";
  phone: string = "";
  name: string = "";
  deletedChildren: any;
  displayedColumns = [ ...filterColumns, 'address', 'delete' ]
  dataSource = new MatTableDataSource<Child>([]); // Toistaseks any, tähä voi olla hyvä myöhemmin laittaa oikee type
  loadingData: boolean = true;
  preschoolGroups: PreschoolType[] = [];
  childrenAll: Child[] = [];
  childrenFiltered: Child[] = [];
  filterActive: boolean = false;
  childrenSubscription: Subscription;
  searchQueryListener = new Subject<string>();
  searchQuery: string = "";
  fuse: Fuse<Child>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private runService: RunService,
    public validationService: ValidationService
  ) {

    this.childrenSubscription = this.runService.childrenObservable.subscribe((children) => {
      this.childrenAll = children;
      this.filterViewData();
    });

    this.searchQueryListener.pipe(debounceTime(500)).subscribe(() => {
      this.filterViewData();
    });

    this.route.params.subscribe((params) => {
      this.runId = params["runId"];
      this.loadingData = true;
      if (this.runId) {
        this.dataSource.data = [];
        this.runService.getOne(this.runId).then((run) => {
          if (run) {
            this.optimizationName = run["name"];
            this.loadingData = false;
          }
        });
      }
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  filterViewData() {

    let childrenFiltered = this.childrenAll.filter(({ status }) => status !== "deleted")

    if (childrenFiltered.length > 0) {

      if (this.filterActive) {
        childrenFiltered = childrenFiltered.filter(({ status }) => status === "invalid")
      }

      if (this.searchQuery.length > 0) {
        this.fuse = new Fuse(childrenFiltered, fuseOptions)
        const fuseResults = this.fuse.search(this.searchQuery)
        childrenFiltered = fuseResults.map((result) => result.item)
      }

      this.dataSource.data = childrenFiltered.sort(
        ({ lastName: lastName1, firstName: firstName1 }, { lastName: lastName2, firstName: firstName2 }) => {
          return lastName1.localeCompare(lastName2) || firstName1.localeCompare(firstName2)
        })

    } else {
      console.log("No children data available")
    }
  }

  handleSearchFilter(e: string) {
    this.searchQuery = e;
    this.searchQueryListener.next();
  }

  phoneValidate() {
    const original = this.runService.children;
    console.log(original);
    const regEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    original.forEach(function (obj) {
      if (!regEx.test(obj["huoltaja 1 matkapuhelin"])) {
        obj["huoltaja 1 matkapuhelin"] = "";
      }
      if (!regEx.test(obj["huoltaja 2 matkapuhelin"])) {
        obj["huoltaja 2 matkapuhelin"] = "";
      }
    });
  }

  hasPhoneNumber(child: object) {
    const number1: string = child["huoltaja 1 matkapuhelin"];
    const number2: string = child["huoltaja 2 matkapuhelin"];
    return number1.length > 1 || number2.length > 1;
  }

  deleteItem = (childId:string) => {
    const childrenUpdated = this.childrenAll.map((child) => {
      if (child.id === childId) child.status = "deleted";
      return child;
    });
    this.runService.updateSourceChildren(this.runId, childrenUpdated);
  };

  openEditAddressDialog(child) {
    const dialogRef = this.dialog.open(EditAddressDialogComponent, {
      data: child,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.updateAddress(result);
    });
  }

  openEditPhoneDialog(child) {
    const dialogRef = this.dialog.open(UpdateBoxComponent, { data: { child } });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  updateAddress(data:UpdatedAddressObject) {

    const oldAddress = data.oldAddress
    const newAddress = data.newAddress
    
    if (oldAddress && newAddress) {

      const childrenUpdated = this.childrenAll.map((child) => {

        if (child.id === oldAddress.entityId) {
          
          child.address = newAddress;

          if (newAddress.addressUuid) {
            child.status = '';
          }

          return child;
        } else {
          return child
        }
      });

      this.runService.updateSourceChildren(this.runId, childrenUpdated);
    }
  }

  toggleFilterMissingRows(e) {
    this.filterActive = e.checked;
    this.filterViewData();
  }

  ngOnDestroy() {
    this.childrenSubscription.unsubscribe();
  }
}
