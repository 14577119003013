import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Helpers} from '../../services/helpers';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(private router: Router, public helpers: Helpers, public translate: TranslateService) {

  }

  onNew(): void {
    this.router.navigate(['users/new']);
  }

  ngOnInit(): void {
    if (!this.helpers.canAccess('USER_GETALL')) {
      this.router.navigate(['']);
    }
  }

}

