import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Child } from "src/app/models/entities";

@Component({
  selector: "app-deleted-children-dialog",
  templateUrl: "./deleted-children-dialog.component.html",
  styleUrls: ["./deleted-children-dialog.component.scss"],
})
export class DeletedChildrenDialogComponent implements OnInit {
  childrenSelected: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { childrenDeleted: Child[] }) {}

  ngOnInit(): void {}

  restoreChildren() {
    if (this.childrenSelected.length > 0) {
      return this.childrenSelected;
    } else return [];
  }
}
