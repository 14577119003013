<div class="container-sm p-0">
  <h2 mat-dialog-title>Palauta poistetut lapset</h2>
  <mat-dialog-content class="mat-typography">
    <p>Valitut lapset palaavat tämän jälkeen taulukkonäkymään.</p>
    <mat-selection-list [(ngModel)]="childrenSelected" class="mt-2 mb-4 mat-elevation-z10">
      <mat-list-option *ngFor="let child of data.childrenDeleted" [value]="child.id">
        {{ child.firstName }} {{ child.lastName }}
      </mat-list-option>
    </mat-selection-list>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="border-top">
    <button mat-button mat-dialog-close>Peruuta</button>
    <button
      mat-button
      color="primary"
      [disabled]="childrenSelected.length === 0"
      [mat-dialog-close]="restoreChildren()"
    >
      Palauta
    </button>
  </mat-dialog-actions>
</div>
