import {Component} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {Helpers} from '../../services/helpers';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {

  isAuthenticated: boolean;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService, private userService: UserService, public helpers: Helpers) {
    this.isAuthenticated = this.authService.isAuthenticated();

    this.userService.logInOutEvent.subscribe(() => {
      this.isAuthenticated = this.authService.isAuthenticated();
    });
  }

}
