import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Role} from '../models/role';
import {Helpers} from './helpers';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) {
  }

  public getAll() {
    return this.http.get(environment.backendUrl + '/roles', Helpers.getToken()).toPromise();
  }

  public getOne(id: number) {
    return this.http.get(environment.backendUrl + '/roles/' + id, Helpers.getToken()).toPromise();
  }

  public getPermissions(roleId: number) {
    return this.http.get(environment.backendUrl + '/roles/' + roleId + '/permissions', Helpers.getToken()).toPromise();
  }

  public create(role: Role, permissions: number[]) {
    return this.http.post(environment.backendUrl + '/roles', {role, permissions}, Helpers.getToken()).toPromise();
  }

  public update(role: Role) {
    return this.http.put(environment.backendUrl + '/roles', role, Helpers.getToken()).toPromise();
  }

  public deleteOne(id: number) {
    return this.http.delete(environment.backendUrl + '/roles/' + id, Helpers.getToken()).toPromise();
  }

  public updatePermissions(roleId: number, permissions: number[]) {
    return this.http.post(environment.backendUrl + '/roles/' + roleId + '/permissions', {permissions}, Helpers.getToken()).toPromise();
  }
}
