<app-command-bar>

  <button (click)="onNew()" mat-button [disabled]="!helpers.canAccess('ROLE_MODIFY')">
    <mat-icon>accessibility_new</mat-icon>
    {{'role.newRole' | translate}}
  </button>

</app-command-bar>

<app-role-table></app-role-table>
