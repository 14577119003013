import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment as env } from "src/environments/environment";
import { Child, PreschoolType } from "../models/entities";
import Optimization from "../models/optimization";
import { Run } from "../models/run";
import RunStatus from "../models/run-status";
import { Helpers } from "./helpers";

@Injectable({
  providedIn: "root",
})
export class RunService {
  childrenObservable: Subject<Child[]>;
  preschoolObservable: Subject<PreschoolType[]>;
  optimizationObservable: Subject<Optimization>;
  optimizationStateObservable: Subject<string | null>;
  optimizationOutdatedObservable: Subject<boolean>;

  runId?: number;
  children?: Child[];
  preschoolGroups?: PreschoolType[];
  optimization?: object;
  optimizationState?: string | null;
  optimizationLastUpdated?: string | null;

  updateInterval;

  constructor(private http: HttpClient) {
    this.preschoolObservable = new Subject<PreschoolType[]>();
    this.childrenObservable = new Subject<Child[]>();
    this.optimizationObservable = new Subject<Optimization>();
    this.optimizationStateObservable = new Subject<string | null>();
    this.optimizationOutdatedObservable = new Subject<boolean>();
  }

  startUpdateInterval() {
    this.updateInterval = setInterval(() => {
      this.getOneStatus(this.runId).then((result) => {
        const outdated = result.result_last_updated.split(".")[0] !== this.optimizationLastUpdated;
        if (outdated) this.optimizationOutdatedObservable.next(true);
      });
    }, 5000);
  }

  stopUpdateInterval() {
    if (this.updateInterval) clearInterval(this.updateInterval);
  }

  getOneStatus(runId: number) {
    return this.http
      .get(env.backendUrl + `/run/${runId}?mode=preview`, Helpers.getToken())
      .toPromise()
      .then((result: Run) => result);
  }

  startRun(runId: number, optimization: { children: object[]; preschoolGroups: object[] }) {
    return this.http
      .post(env.backendUrl + `/run/optimization/${runId}`, { optimization }, Helpers.getToken())
      .toPromise()
      .then((optimizationId) => optimizationId);
  }

  getRunStatus() {
    return this.http
      .get(env.backendUrl + "/run/optimization/status", Helpers.getToken())
      .toPromise()
      .then((status: RunStatus[]) => status);
  }

  getRunStatusById(optimizationId: string) {
    return this.http
      .get(env.backendUrl + `/run/optimization/${optimizationId}`, Helpers.getToken())
      .toPromise()
      .then((data: { _id: string; input: Optimization; status: string; result: Optimization }) => data);
  }

  updateResultState(runId: number, newState: string | null) {
    const body = { result_state: newState };
    this.updateResultOne(runId, body).then((updatedRun) => {
      const optimizationState = updatedRun["result_state"];
      this.optimizationState = optimizationState;
      this.optimizationStateObservable.next(optimizationState);
    });
  }

  updateResult2(runId: number, childId: string, newPreschoolGroup: string) {
    return this.http
      .put(
        env.backendUrl + `/run/result/${runId}/${childId}?preschoolGroup=${newPreschoolGroup}`,
        null,
        Helpers.getToken()
      )
      .toPromise()
      .then((response: { successful: boolean; status: string; result_last_updated: string }) => {
        this.optimizationLastUpdated = response.result_last_updated.split(".")[0];
        return response;
      });
  }

  optimizationChildDeletedStatus(runId: number, childId: string, deleted: boolean) {
    return this.http
      .put(env.backendUrl + `/run/result/${runId}/${childId}/status?deleted=${deleted}`, null, Helpers.getToken())
      .toPromise()
      .then((response: { successful: boolean; status: string; result_last_updated: string }) => {
        this.optimizationLastUpdated = response.result_last_updated.split(".")[0];
        return response;
      });
  }

  updateSourceChildren = (runId: number, updatedChildren: Child[]) => {
    const body = { source_children: updatedChildren };
    this.updateSourceOne(runId, body).then((response) => {
      const newChildrenValue = response["source_children"];
      this.children = newChildrenValue;
      this.childrenObservable.next(newChildrenValue);
    });
  };

  updateSourcePreschoolGroups = (runId: number, updatedPreschoolGroups:PreschoolType[]) => {
    const body = { source_preschool_groups: updatedPreschoolGroups };
    this.updateSourceOne(runId, body).then((response) => {
      const newPreschoolGroupsValue = response["source_preschool_groups"];
      this.preschoolGroups = newPreschoolGroupsValue;
      this.preschoolObservable.next(newPreschoolGroupsValue);
    });
  };

  updateSourceOne = (runId: number, valuesToUpdate: object) => {
    return this.http
      .put(env.backendUrl + `/run/source/${runId}`, valuesToUpdate, Helpers.getToken())
      .toPromise()
      .then((data: object) => data);
  };

  updateResultOne = (runId: number, valuesToUpdate: object) => {
    return this.http
      .put(env.backendUrl + `/run/result/${runId}`, valuesToUpdate, Helpers.getToken())
      .toPromise()
      .then((data: object) => data);
  };

  getAll = () =>
    this.http
      .get(env.backendUrl + "/run", Helpers.getToken())
      .toPromise()
      .then((data: Run[]) => data);

  getOne = (runId: number) =>
    this.http
      .get(env.backendUrl + `/run/${runId}`, Helpers.getToken())
      .toPromise()
      .then((run: Run | null) => {
        if (run) {
          
          const children = run["source_children"] ? run["source_children"] : [];
          const daycareProviders = run["source_daycare_providers"] ? run["source_daycare_providers"] : null;
          const preschoolGroups = run["source_preschool_groups"] ? run["source_preschool_groups"] : null;
          const optimizationModified = run["result_modified"] ? (run["result_modified"] as Optimization) : null;
          const optimizationState = run["result_state"] ? run["result_state"] : null;
          const resultLastUpdated = run["result_last_updated"] ? run["result_last_updated"] : null;

          if (runId && children && daycareProviders && preschoolGroups) {
            this.runId = runId;
            this.children = children;
            this.childrenObservable.next(children);
            this.preschoolGroups = preschoolGroups;
            this.preschoolObservable.next(preschoolGroups);
            this.optimizationState = optimizationState;
            this.optimizationStateObservable.next(optimizationState);
          }

          if (resultLastUpdated) this.optimizationLastUpdated = resultLastUpdated.split(".")[0];

          if (optimizationModified) {
            this.optimization = optimizationModified;
            this.optimizationObservable.next(optimizationModified);
          } else {
            const optimizationOriginal = run["result_original"] ? (run["result_original"] as Optimization) : null;
            if (optimizationOriginal) {
              this.optimization = optimizationOriginal;
              this.optimizationObservable.next(optimizationOriginal);
            } else {
              const optimizationId = run["optimization_id"] ? run["optimization_id"] : null;
              if (optimizationId) {
                this.getRunStatusById(optimizationId).then(({ result }) => {
                  const valuesToUpdate = {
                    result_original: result,
                    result_modified: result,
                  };
                  this.optimizationObservable.next(result);
                  this.updateResultOne(runId, valuesToUpdate);
                });
              }
            }
          }
        } else {
          this.runId = null;
          this.children = null;
          this.childrenObservable.next([]);
          this.preschoolGroups = [];
          this.preschoolObservable.next([]);
        }

        return run;
      });

  deleteOne = (runId: number) =>
    this.http
      .delete(env.backendUrl + `/run/${runId}`, Helpers.getToken())
      .toPromise()
      .then(() => true);

  createOne = (run: {
    name: string;
    source_children: object;
    source_preschool_groups: object;
  }) =>
    this.http
      .post(env.backendUrl + "/run", run, Helpers.getToken())
      .toPromise()
      .then((data: Run) => data);
}
