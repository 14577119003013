<div class="csv-file-chooser-section form-container">
  <ul class="form box-shadow">
    <h2 class="form-title">{{ "optimization.new" | translate }}</h2>
    <li class="form-row">
      <label for="text" class="name-label"
        ><b>{{ "optimization.name" | translate }}</b></label
      >
      <input
        type="text"
        name="Run name"
        class="name-input"
        [(ngModel)]="runName"
        id="text"
        placeholder="{{ 'optimization.name' | translate }}"
      />
    </li>
    <li class="form-row">
      <label class="custom-file-upload">
        <input
          type="file"
          name="Upload public resources"
          accept=".csv"
          (change)="uploadPreschoolResources($event)"
        />
        Lataa esiopetuspaikat
      </label>
      <p *ngIf="!preschoolResourcesData.length">
        {{ "optimization.municipal_info" | translate }}
      </p>
      <p *ngIf="preschoolResourcesData.length">
        Ladattu {{ preschoolResourcesData.length }} esiopetuspaikkaa
      </p>
    </li>
    <!-- <li class="form-row">
      <label class="custom-file-upload">
        <input
          type="file"
          name="Upload private resources"
          accept=".csv"
          (change)="uploadPrivateResources($event)"
        />
        Lataa päiväkodit
      </label>
      <p *ngIf="!daycareProvidersResourcesData.length">
        {{ "optimization.private_info" | translate }}
      </p>
      <p *ngIf="daycareProvidersResourcesData.length">
        Ladattu {{ daycareProvidersResourcesData.length }} yksityistä esiopetuspaikkaa
      </p>
      </li> -->
    <li class="form-row">
      <label class="custom-file-upload">
        <input type="file" name="File Upload" accept=".csv" (change)="uploadChildren($event)" />
        Lataa lasten tiedot
      </label>
      <p *ngIf="!childrenData.length">Lataa lasten tiedot</p>
      <p *ngIf="childrenData.length">
        Ladattu {{ childrenData.length }} lapsen tiedot
      </p>
    </li>
    <div class="submit-button">
      <button
        mat-flat-button
        color="primary"
        class="py-2 px-3"
        [disabled]="disableForward()"
        (click)="saveOptimization()"
      >
        <mat-spinner
          *ngIf="loadingAddresses"
          color="accent"
          [diameter]="25"
          class="d-inline-block mr-2"
        ></mat-spinner>
        {{ loadingAddresses ? "Valmistellaan" : "Seuraava" }}
      </button>
    </div>
  </ul>
</div>
