<mat-card>
  <mat-card-content>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <h2>{{'login.title' | translate}}</h2>
      <mat-error *ngIf="loginInvalid">
        {{'login.generalError' | translate}}
      </mat-error>
      <mat-form-field class="full-width-input">
        <input formControlName="username" matInput placeholder="{{'login.username' | translate}}" required>
        <mat-error *ngIf="!form.controls['username'].valid && form.controls['username'].touched">
          {{'login.usernameError' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input formControlName="password" matInput placeholder="{{'login.password' | translate}}" required
               type="password">
        <mat-error *ngIf="!form.controls['password'].valid && form.controls['password'].touched">
          {{'login.passwordError' | translate}}
        </mat-error>
      </mat-form-field>
      <button color="primary" mat-raised-button>{{'login.loginButton' | translate}}</button>
    </form>
  </mat-card-content>
</mat-card>
