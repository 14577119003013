<div *ngIf="!isLoading">
  <div class="mt-2">
    <h2>Esiopetuspaikat</h2>
  </div>

  <mat-accordion *ngIf="optimizationData">
    <mat-expansion-panel *ngFor="let group of optimizationData.solution.group_list">
      <mat-expansion-panel-header>
        <div class="d-flex align-items-center w-100 justify-content-between">
          <mat-panel-title> {{ group["name"] }}</mat-panel-title>
          <div class="d-flex flex-column mr-1">
            <span
              class="badge badge-dark"
              style="background-color: #ff4081"
              *ngIf="group.WITH_SCHOOL"
              >V</span
            >
            <!-- <span
              class="badge badge-dark"
              style="background-color: #3f51b5"
              *ngIf="group['properties'].includes('kielikylpy')"
              >K</span
            > -->
            <!-- <span
            class="badge badge-dark"
            style="background-color: #ff4081"
            *ngIf="[].includes('vuorohoito')"
            >V</span
          >
          <span
            class="badge badge-dark"
            style="background-color: #3f51b5"
            *ngIf="[].includes('kielikylpy')"
            >K</span
          > -->

          </div>
          <mat-panel-description class="flex-grow-0">
            <mat-chip-list aria-label="Fish selection">
              <mat-chip
                *ngIf="group['capacity']"
                [ngClass]="{
                  'bg-success': countChildrenInPreschool(group['id']) / group['capacity'] <= 1,
                  'bg-danger': countChildrenInPreschool(group['id']) / group['capacity'] > 1
                }"
                >{{ countChildrenInPreschool(group["id"]) }}/{{ group["capacity"] }}</mat-chip
              >
            </mat-chip-list>
          </mat-panel-description>
        </div>
      </mat-expansion-panel-header>
      <p><strong>Osoite: </strong>{{ parsePreschoolAddress(group["id"]) }}</p>
      <p><strong>Koulun yhteydessä: </strong>{{ group.WITH_SCHOOL ? "kyllä" : "ei" }}</p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
