import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../services/auth.service';
import {version} from '../../../../package.json';
import {environment} from '../../../environments/environment';
import {VersionService} from '../../services/version.service';
import {Helpers} from '../../services/helpers';


@Component({
    selector: 'app-navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit {
    @Output() toggleSidenav = new EventEmitter<void>();
    isAuthenticated: boolean;

    public version: string = version;
    public environmentName: string = environment.environmentName;
    public backendVersion: string;
    public backendEnvironment: string;

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(private breakpointObserver: BreakpointObserver, private router: Router, private userService: UserService, private authService: AuthService, private versionService: VersionService, public helpers: Helpers) {
        this.isAuthenticated = this.authService.isAuthenticated();

        this.userService.logInOutEvent.subscribe(() => {
            this.isAuthenticated = this.authService.isAuthenticated();
        });

        this.versionService.get().then((result: any) => {
            this.backendVersion = result.version;
            this.backendEnvironment = result.environment;
        });
    }

    ngOnInit(): void {
    }

    async logOut() {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        localStorage.removeItem('permissions');

        this.userService.logInOutEvent.emit();
        await this.router.navigate(['login']);
    }

  toFrontPage() {
      this.router.navigate(['']).then();
  }
}
