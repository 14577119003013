import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Role} from '../../models/role';
import {RoleService} from '../../services/role.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {Permission} from '../../models/permission';
import {PermissionService} from '../../services/permission.service';
import {Helpers} from '../../services/helpers';

@Component({
  selector: 'app-role-wizard',
  templateUrl: './role-wizard.component.html',
  styleUrls: ['./role-wizard.component.scss']
})
export class RoleWizardComponent implements OnInit {

  formGroup: FormGroup;
  role: Role;
  isLoading = true;
  saveFailed = false;
  rolePermissionIds: number[];
  availablePermissions: Permission[];

  constructor(private roleService: RoleService,
              private permissionService: PermissionService,
              private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              public helpers: Helpers,
              private location: Location) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.fetchInitialData(Number(id)).then(async _ => {
        await this.createForm();
        this.isLoading = false;
      });
    });
  }

  async fetchInitialData(id: number): Promise<void> {
    const promises: Promise<any>[] = [];

    const availablePermissions = this.permissionService.getAll().then((res: Permission[]) => {
      this.availablePermissions = res;
    });
    promises.push(availablePermissions);

    if (id) {
      const role = this.roleService.getOne(Number(id)).then((res: Role) => {
        this.role = res;
      });

      const rolePermissionIds = this.roleService.getPermissions(id).then((res: Permission[]) => {
        this.rolePermissionIds = res.map(permission => permission.id);
      });

      promises.push(...[role, rolePermissionIds]);
    }
    await Promise.all(promises);
  }

  async createForm(): Promise<void> {
    this.formGroup = this.formBuilder.group({
      name: [this.role?.name, Validators.required],
      permissions: [this.rolePermissionIds],
    });

    if (!this.helpers.canAccess('ROLE_MODIFY')) {
      this.formGroup.disable();
    }
  }

  async onSave(): Promise<void> {
    /** If role exists, then update, otherwise create new */
    if (this.role) {
      // Merge role objects
      const role = {...this.role, ...this.formGroup.value};
      const roleUpdate = this.roleService.update(role);
      const permissionsUpdate = this.roleService.updatePermissions(role.id, role.permissions);

      await Promise.all([roleUpdate, permissionsUpdate])
        .then(_ => this.location.back())
        .catch(_ => this.saveFailed = true);

    } else {
      const role = this.formGroup.value;
      this.roleService.create(role, role.permissions)
        .then(_ => this.location.back())
        .catch(_ => this.saveFailed = true);
    }
  }

  onClose(): void {
    this.location.back();
  }

  onDelete() {
    if (confirm('Oletko varma?')) {
      this.roleService.deleteOne(this.role.id).then(() => {
        this.location.back();
      });
    }
  }

}
