<div class="container-fluid d-flex justify-content-between align-items-center border-top py-3">
  <div>
    <!-- <button
      mat-stroked-button
      color="warn"
      [matBadge]="childrenDeleted.length > 0 ? childrenDeleted.length : null"
      matBadgeColor="warn"
      [disabled]="childrenDeleted.length === 0 || optimizationStatus"
      (click)="openDeletedChildrenDialog()"
    >
      Poistetut lapset
    </button>
    <button
      mat-button
      [disabled]="!(childrenDeleted.length === 0 || optimizationStatus)"
      (click)="downloadDeletedChildren()"
    >
      Lataa poistetut lapset
    </button> -->
  </div>

  <div *ngIf="dataIsOutdated">
    <button mat-stroked-button (click)="reloadData()">Päivitä tiedot</button>
  </div>

  <div>
    <button mat-button disabled *ngIf="optimizationStatus === 'send messages'">Viestit lähetetty / lähetetään</button>
    <button mat-flat-button color="accent" *ngIf="optimizationStatus === null" (click)="changeStatus('complete')">
      Merkitse valmiiksi
    </button>
    <button mat-flat-button *ngIf="optimizationStatus === 'complete'" (click)="changeStatus('send messages')" disabled>
      Lähetä viesti
    </button>
    <button
      mat-flat-button
      color="accent"
      class="ml-2"
      *ngIf="optimizationStatus === 'complete'"
      (click)="changeStatus(null)"
    >
      Palauta muokattavaksi
    </button>
    <button mat-button disabled *ngIf="optimizationStatus === 'send messages'">Viestit lähetetty / lähetetään</button>
    <button mat-flat-button color="primary" class="ml-2" (click)="downloadRun()" [disabled]="!optimizationStatus">
      Lataa optimointi
    </button>
  </div>
</div>
