<mat-toolbar color="primary">
  <mat-toolbar-row class="toolbar">
    <img fxHide.lt-md class="logo" src="assets/img/turku_pysty_300ppi_viiva_white.png" />
    <!-- <ng-container *ngIf="(isHandset$ | async)">
      <button (click)="toggleSidenav.emit()" mat-icon-button *ngIf="isAuthenticated">
        <mat-icon>menu</mat-icon>
      </button>
    </ng-container> -->
    <h2 class="toolbar-title" (click)="toFrontPage()">Esiopetuspaikkojen allokointi</h2>
    <span class="version">
      <small>{{ environmentName }} v.{{ version }} ({{ backendEnvironment }} v.{{ backendVersion }})</small>
    </span>

    <div *ngIf="isAuthenticated" fxFlex fxLayout fxLayoutAlign="flex-end">
      <ul fxLayout fxLayoutGap="28px" style="list-style-type: none">
        <li routerLink="/" routerLinkActive="list-item-active" *ngIf="helpers.canAccess('USER_GETALL')">
          <span mat-line>{{ "nav.optimizations" | translate }}</span>
        </li>

        <li routerLink="users" routerLinkActive="list-item-active" *ngIf="helpers.canAccess('USER_GETALL')">
          <span mat-line>{{ "nav.users" | translate }}</span>
        </li>

        <li routerLink="roles" routerLinkActive="list-item-active" *ngIf="helpers.canAccess('ROLE_GETALL')">
          <span mat-line>{{ "nav.roles" | translate }}</span>
        </li>

        <li routerLink="permissions" routerLinkActive="list-item-active" *ngIf="helpers.canAccess('PERMISSION_GETALL')">
          <span mat-line>{{ "nav.permissions" | translate }}</span>
        </li>

        <button [matMenuTriggerFor]="auth" mat-icon-button>
          <mat-icon>person_outline</mat-icon>
        </button>

        <mat-menu #auth="matMenu">
          <button (click)="logOut()" mat-menu-item>
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ "nav.signout" | translate }}</span>
          </button>
        </mat-menu>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
