<h2 mat-dialog-title>Muokkaa osoitetta</h2>
<mat-dialog-content class="mat-typography">
  <p>
    Alkuperäinen osoite: {{ data.address.street }}, {{ data.address.city }}
    <br />
    <span *ngIf="!data.address.addressUuid" style="color: red">Osoite ei ole yhteensopiva</span>
  </p>

  <div class="">
    <mat-form-field class="d-block" appearance="fill">
      <mat-label>Hae osoitetta (väh. 5 kirjainta)</mat-label>
      <input
        matInput
        type="search"
        placeholder="Ex. Porarinpolku..."
        [ngModel]="addressInput"
        (ngModelChange)="updateInput($event)"
      />
    </mat-form-field>
  </div>

  <mat-selection-list #shoes [multiple]="false">
    <!-- <mat-list-option
      *ngIf="addressValidated && !addressInput.includes(addressValidated['street'])"
      [value]="addressValidated['street']"
      (click)="updateInput(addressInput)"
    >
      <div mat-line>{{ addressValidated["street"] }}</div>
      <div mat-line>Ehdotus</div>
    </mat-list-option> -->
    <mat-list-option
      *ngFor="let result of searchResult"
      [value]="result.name"
      [selected]="result.name === addressInput"
      (click)="selectInput(result)"
    >
      {{ result.street }}, {{ result.postcode }} {{ result.city }}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Peruuta</button>
  <button mat-button [mat-dialog-close]="getNewAddress()" [disabled]="!addressValidated || !addressValidated.addressUuid">Hyväksy</button>
</mat-dialog-actions>
