import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import RunStatus from "src/app/models/run-status";
import { RunService } from "src/app/services/run.service";
import { Run } from "../../models/run";

@Component({
  selector: "app-front-page",
  templateUrl: "./front-page.component.html",
  styleUrls: ["./front-page.component.scss"],
})
export class FrontPageComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isLoadingResults = true;
  dataSource = new MatTableDataSource<Run>([]);
  runStatus: RunStatus[] = [];

  /** Columns that are going to be displayed */
  displayedColumns = ["name", "created", "status", "actions"];

  constructor(private runService: RunService) {}

  ngOnInit(): void {
    /** Sorting for nested objects */
    this.dataSource.sort = this.sort;
    this.loadRuns();
    this.loadRunStatus();
  }

  loadRuns() {
    this.runService.getAll().then((runs) => {
      this.dataSource.data = runs;
      this.isLoadingResults = false;
    });
  }

  loadRunStatus() {
    this.runService.getRunStatus().then((status) => {
      this.runStatus = status;
    })
  }

  parseDate(date: string) {
    try {
      const parsedDate = date.replace("T", " ").split(".")[0].split("-").join(".");
      return parsedDate;
    } catch (error) {
      return "";
    }
  }

  openOptimization(optimizationId: number) {
    alert("Navigate to Optimization with ID: " + optimizationId);
  }

  deleteOptimization(run: Run) {
    const deleteConfirmed = confirm("Haluatko varmasti poistaa seuraavan optimoinnin: " + run.name);
    if (deleteConfirmed) {
      this.runService.deleteOne(run.id).then(() => this.loadRuns());
    }
  }

  optimizationStatus(optimizationId: string) {
    const optimization = this.runStatus.find((status) => status.id === optimizationId);
    if (optimization) return optimization.status;
    else return "";
  }
}
