import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input()
  text!: string;
  @Input()
  color!: string;
  @Input()
  type!: string;
  @Input()
  value!: string;
  @Output() btnClick = new EventEmitter();
  matButtonModule = new MatButtonModule();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.btnClick.emit();
  }
}
