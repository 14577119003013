<mat-tab-group mat-align-tabs="center">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>accessibility_new</mat-icon>
      {{'role.singleTitle' | translate}}
    </ng-template>

    <ng-container *ngIf="!isLoading">
      <app-command-bar>
        <button (click)="onSave()" [disabled]="!formGroup.valid || !helpers.canAccess('ROLE_MODIFY')" mat-button>
          <mat-icon>save</mat-icon>
          {{'buttons.save' | translate}}
        </button>

        <button (click)="onDelete()" [disabled]="!helpers.canAccess('ROLE_DELETE')" mat-button>
          <mat-icon>delete</mat-icon>
          {{'buttons.delete' | translate}}
        </button>

        <button (click)="onClose()" mat-button>
          <mat-icon>cancel</mat-icon>
          {{'buttons.close' | translate}}
        </button>

      </app-command-bar>

      <div [formGroup]="formGroup" class="container-fluid">
        <mat-card>
          <mat-card-title>{{'role.information' | translate}}</mat-card-title>

          <mat-card-content>

            <div class="row">
              <div class="col">
                <mat-form-field class="form-element">
                  <input formControlName="name" matInput placeholder="{{'role.name' | translate}}">
                  <mat-error *ngIf="!formGroup.controls['name'].valid && formGroup.controls['name'].touched">
                    {{'miscellaneous.required' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <mat-form-field class="form-element">
              <mat-label>{{'permission.title' | translate}}</mat-label>
              <mat-select formControlName="permissions" multiple>
                <mat-option *ngFor="let permission of availablePermissions"
                            [value]="permission.id">{{permission.name}}</mat-option>
              </mat-select>
            </mat-form-field>

          </mat-card-content>
        </mat-card>

      </div>
    </ng-container>

    <mat-card *ngIf="isLoading" class="loading-indicator">
      <mat-progress-spinner
          color="primary"
          mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>

  </mat-tab>

</mat-tab-group>


