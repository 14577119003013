import { Injectable } from "@angular/core";
import { parse, unparse } from "papaparse";
import { PapaParseValueObject } from "../models/papaParseObject";

@Injectable({
  providedIn: "root",
})
export class CsvDataService {

  parseCsv(file: File, encoding?: string): Promise<PapaParseValueObject[]> {
    return new Promise(resolve => parse<PapaParseValueObject>(file, {
      header: true,
      skipEmptyLines: true,
      encoding: encoding ? encoding : "utf-8", // Hard coded for now, should be detected automatically
      complete: (results) => resolve(results.data),
    }))
  }

  jsonToCsv(data: object[]) {
console.log(data)
    // const dataAsCsv = this.papa.unparse(data);
    return unparse(data);
    // return dataAsCsv;
  }
}
