<div class="container-fluid d-flex justify-content-between align-items-center border-top">
  <div class="py-3">
    <button
      mat-stroked-button
      color="warn"
      [matBadge]="childrenDeleted.length > 0 ? childrenDeleted.length : null"
      matBadgeColor="warn"
      [disabled]="childrenDeleted.length === 0"
      (click)="openDeletedChildrenDialog()"
    >
      Poistetut lapset
    </button>
    <button
      class="ml-3"
      mat-stroked-button
      color="accent"
      [matBadge]="childrenInvalid.length > 0 ? childrenInvalid.length : null"
      matBadgeColor="accent"
      [disabled]="childrenInvalid.length === 0"
      (click)="openInvalidChildrenDialog()"
    >
      Lapset puutteellisella tiedolla
    </button>
  </div>
  <div>
    <!-- <button mat-button>Peruuta</button> -->
    <button mat-flat-button color="primary" (click)="startRun()" [disabled]="startButtonDisabled">Aloita optimointi</button>
  </div>
</div>
