import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Helpers} from './helpers';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  @Output() logInOutEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {
  }

  public getAll() {
    return this.http.get(environment.backendUrl + '/users', this.getToken()).toPromise();
  }

  public getOne(id: number) {
    return this.http.get(environment.backendUrl + '/users/' + id, this.getToken()).toPromise();
  }

  public register(user, specialDiets) {
    return this.http.post(environment.backendUrl + '/users/register', {user, specialDiets}, this.getToken()).toPromise();
  }

  public login(username: string, password: string) {
    return this.http.post(environment.backendUrl + '/users/login', {username, password}, this.getToken()).toPromise();
  }

  public update(user) {
    return this.http.put(environment.backendUrl + '/users', user, this.getToken()).toPromise();
  }

  public searchUser(query: string) {
    return this.http.get(environment.backendUrl + '/users/search/user/' + query, Helpers.getToken()).toPromise();
  }

  public getSome(from, amount) {
    return this.http.get(environment.backendUrl + '/users/paginate/' + from + '/' + amount, Helpers.getToken()).toPromise();
  }

  public count() {
    return this.http.get(environment.backendUrl + '/users/paginate/count', Helpers.getToken()).toPromise();
  }

  public deleteOne(id: number) {
    return this.http.delete(environment.backendUrl + '/users/' + id, Helpers.getToken()).toPromise();
  }

  private getToken() {
    return localStorage.getItem('token') ? {
      headers: new HttpHeaders({
        Authorization: 'Token ' + localStorage.getItem('token')
      })
    } : {};
  }
}
