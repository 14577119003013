import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "src/environments/environment";
import { Address, HasSchoolDistrictType, LocatedEntity } from "../models/entities";

type AddressResolveResponse = Address & HasSchoolDistrictType

@Injectable({
  providedIn: "root",
})
export class TcdApiService {

  constructor(private http: HttpClient) { }

  validateAllAddresses = async (entities: LocatedEntity[]): Promise<AddressResolveResponse[]> => {

    const body = entities.map((entity) => {
      const { id: entityId, address: { street, postcode, city } } = entity
      return {
        entityId,
        raw: `${street}, ${postcode} ${city}`,
      }
    })

    return this.http
      .post(env.tcdApiUrl + "/address-resolve/batch-resolve", { addresses: body })
      .toPromise<any>()
  };

  validateAddress = async (address:string): Promise<AddressResolveResponse[]> => {

    return this.http
      .get(`${env.tcdApiUrl}/address-resolve/${address}`)
      .toPromise<any>()
  }
}
