import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Permission} from '../models/permission';
import {Helpers} from './helpers';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private http: HttpClient) {
  }

  public getAll() {
    return this.http.get(environment.backendUrl + '/permissions', Helpers.getToken()).toPromise();
  }

  public getOne(id: number) {
    return this.http.get(environment.backendUrl + '/permissions/' + id, Helpers.getToken()).toPromise();
  }

  public create(permission: Permission) {
    return this.http.post(environment.backendUrl + '/permissions', permission, Helpers.getToken()).toPromise();
  }

  public update(permission: Permission) {
    return this.http.put(environment.backendUrl + '/permissions', permission, Helpers.getToken()).toPromise();
  }

  public deleteOne(id: number) {
    return this.http.delete(environment.backendUrl + '/permissions/' + id, Helpers.getToken()).toPromise();
  }
}
