import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CsvDataService } from 'src/app/services/csvDataService';
import { RunService } from 'src/app/services/run.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-update-box',
  templateUrl: './update-box.component.html',
  styleUrls: ['./update-box.component.scss']
})
export class UpdateBoxComponent implements OnInit {
  phoneValidated: boolean = false;
  @Input() phoneInput: string = "";
  inputChanged = new Subject<string>();



    constructor(
      @Inject(MAT_DIALOG_DATA) public data: { child },
      public dialogRef: MatDialogRef<UpdateBoxComponent>,
      public csvDataService: CsvDataService,
      public runService: RunService,
      public validationService: ValidationService,
    ) {
      this.inputChanged.pipe(debounceTime(500)).subscribe((value) => {
        this.validationService.validatePhone(value);
      });
    }
    
    ngOnInit(): void {
      if (this.data.child["lapsen osoite"]) {
        const phone = this.data.child["huoltaja 1 matkapuhelin"];
        this.phoneInput = phone;
        this.validationService.validatePhone(phone);
      }
    }
  
    updateInput(value: string) {
      this.phoneInput = value;
      if (value.length > 4) {
        this.inputChanged.next(value);
      }
    }

        getNewPhone() {
          return {
            oldPhone: this.data.child["huoltaja 1 maktapuhelin"],
            newAddress: this.phoneInput
         };
        }

}