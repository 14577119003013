import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Child } from "src/app/models/entities";
import Optimization from "src/app/models/optimization";
import { CsvDataService } from "src/app/services/csvDataService";
import { RunService } from "src/app/services/run.service";
import { DeletedChildrenDialogComponent } from "../../shared/deleted-children-dialog/deleted-children-dialog.component";

@Component({
  selector: "app-complete-optimization-bottombar",
  templateUrl: "./complete-optimization-bottombar.component.html",
  styleUrls: ["./complete-optimization-bottombar.component.scss"],
})
export class CompleteOptimizationBottombarComponent implements OnInit, OnDestroy {
  runId: number;
  optimizationStatus: string | null;
  optimization: Optimization;
  childrenDeletedIds: string[];
  childrenDeleted: Child[] = [];
  optimizationSubscription: Subscription;
  optimizationStatusSubscription: Subscription;
  dataIsOutdated: boolean = false;
  optimizationOutdatedListener: Subscription;

  constructor(
    private runService: RunService,
    private route: ActivatedRoute,
    private csvDataService: CsvDataService,
    private dialog: MatDialog
  ) {
    //Listen for run ID
    this.route.params.subscribe((params) => (this.runId = params["runId"]));

    // Listen for optimization status
    this.optimizationStatusSubscription = this.runService.optimizationStateObservable.subscribe(
      (optimizationStatus) => (this.optimizationStatus = optimizationStatus)
    );

    // Listen for optimization data
    this.optimizationSubscription = this.runService.optimizationObservable.subscribe((optimization) => {
      this.optimization = optimization;
      this.childrenDeletedIds = optimization.solution.entity_list.filter(child => child.deleted).map((child) => child.id);
      this.childrenDeleted = this.runService.children.filter((child) => this.childrenDeletedIds.includes(child["id"]));
    });

    // Lister for if local optimization data is outdated
    this.optimizationOutdatedListener = this.runService.optimizationOutdatedObservable.subscribe((dataIsOutdated) => {
      this.dataIsOutdated = dataIsOutdated;
    });
  }

  ngOnInit(): void {}

  changeStatus(newState: string | null) {
    if (this.runId) this.runService.updateResultState(this.runId, newState);
  }

  reloadData() {
    this.runService.getOne(this.runId).then(() => {
      this.runService.optimizationOutdatedObservable.next(false);
    });
  }

  openDeletedChildrenDialog() {
    const deletedChildrenDialog = this.dialog.open(DeletedChildrenDialogComponent, {
      data: { childrenDeleted: this.childrenDeleted },
    });
    deletedChildrenDialog.afterClosed().subscribe((childrenToRestoreIds: string[]) => {
      if (childrenToRestoreIds && childrenToRestoreIds.length > 0) {
        let updatedOptimization: Optimization = JSON.parse(JSON.stringify(this.optimization));
        updatedOptimization.solution.entity_list = updatedOptimization.solution.entity_list.map((child) => {
          if (childrenToRestoreIds.includes(child.id)) {
            child.deleted = false
          }
          return child;
        });
        let promiseArray = [];
        childrenToRestoreIds.map((childId) => {
          promiseArray.push(() => this.runService.optimizationChildDeletedStatus(this.runId, childId, false));
        });
        promiseArray
          .reduce((promiseChain, currentFunction) => promiseChain.then(currentFunction), Promise.resolve())
          .then(() => {
            this.runService.optimization = updatedOptimization;
            this.runService.optimizationObservable.next(updatedOptimization);
          });
      } else console.log("No children provided to restore");
    });
  }

  downloadRun() {

    const exportData = this.optimization.solution.entity_list
      .filter((child) => !this.childrenDeletedIds.includes(child.id))
      .map((child) => ({
        lapsen_id: child.id,
        esiopetusyksikon_id: child.group,
      }))

    const exportCsv = this.csvDataService.jsonToCsv(exportData);
    this.saveAsCsvFile(exportCsv, "lapset-optimointi.csv");
  }

  downloadDeletedChildren() {
    const exportCsv = this.csvDataService.jsonToCsv(this.childrenDeleted);
    this.saveAsCsvFile(exportCsv, "lapset-poistetut.csv");
  }

  saveAsCsvFile(exportCsv: string, filename: string) {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(exportCsv));
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  ngOnDestroy() {
    this.optimizationStatusSubscription.unsubscribe();
    this.optimizationSubscription.unsubscribe();
    this.optimizationOutdatedListener.unsubscribe();
  }
}
