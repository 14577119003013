import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../models/user';
import {UserService} from '../../services/user.service';
import {Location} from '@angular/common';
import {RoleService} from '../../services/role.service';
import {Role} from '../../models/role';
import {Helpers} from '../../services/helpers';

@Component({
  selector: 'app-user-wizard',
  templateUrl: './user-wizard.component.html',
  styleUrls: ['./user-wizard.component.scss']
})
export class UserWizardComponent implements OnInit {
  formGroup: FormGroup;
  user: User;
  roles: Role[];

  isLoading = true;
  saveFailed = false;

  constructor(private userService: UserService,
              private roleService: RoleService,
              private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              public helpers: Helpers,
              private location: Location) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.fetchInitialData(Number(id)).then(async _ => {
        await this.createForm();
        this.isLoading = false;
      });
    });
  }

  async fetchInitialData(id: number): Promise<void> {
    const promises: Promise<any>[] = [];

    const roles = this.roleService.getAll().then((res: Role[]) => {
      this.roles = res;
    });

    promises.push(...[roles]);

    if (id) {
      const user = this.userService.getOne(Number(id)).then((res: User) => {
        this.user = res;
      });

      promises.push(...[user]);
    }

    await Promise.all(promises);
  }

  async createForm(): Promise<void> {
    this.formGroup = this.formBuilder.group({
      username: [this.user?.username, Validators.required],
      password: [''],
      passwordAgain: [''],
      firstName: [this.user?.firstName, Validators.required],
      lastName: [this.user?.lastName, Validators.required],
      email: [this.user?.email, [Validators.required, Validators.email]],
      active: [this.user?.active],
      role_id: [this.user?.role_id]
    });

    if (!this.helpers.canAccess('USER_MODIFY')) {
      this.formGroup.disable();
    }
  }

  async onSave(): Promise<void> {
    /** If user exists, then update, otherwise create new */
    if (this.user) {
      // Merge user objects
      const user = {...this.user, ...this.formGroup.value};

      if (user.password && user.password !== user.passwordAgain) {
        this.formGroup.controls.password.setErrors({noMatch: true});
        this.saveFailed = true;
        return;
      } else {
        if (!user.password) {
          delete user.password;
        }

        delete user.passwordAgain;
      }

      const userUpdate = this.userService.update(user);

      await Promise.all([userUpdate])
        .then(_ => this.location.back())
        .catch(_ => this.saveFailed = true);

    } else {
      const user = this.formGroup.value;

      if (user.password !== user.passwordAgain) {
        this.formGroup.controls.password.setErrors({noMatch: true});
        this.saveFailed = true;
        return;
      }

      delete user.passwordAgain;

      this.userService.register(user, user.specialDiets)
        .then(_ => this.location.back())
        .catch(_ => this.saveFailed = true);
    }
  }

  onClose(): void {
    this.location.back();
  }

}
