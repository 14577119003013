<div class="container-sm p-0">
  <h2 mat-dialog-title>Lapset puutteellisella tiedolla</h2>
  <mat-dialog-content class="mat-typography">
    <p>
      Valitut lapset palaavat jälkeen taulukkonäkymään. Kyseisten lasten osoitetiedot tulee syöttää
      manuaalisesti, jotta optimointityö voitaisiin aloittaa.
    </p>
    <mat-selection-list [(ngModel)]="childrenSelected" class="mt-2 mb-4 mat-elevation-z10">
      <mat-list-option
        *ngFor="let child of data.childrenInvalid"
        [value]="child['id']"
        [disabled]="!child['lapsen nimi']"
      >
        <div mat-line>{{ child.lastName }} {{ child.firstName }} </div>
        <div mat-line>{{ child.address ? child.address.street + ', ' + child.address.postcode + ' ' + child.address.city : "-" }}</div>
      </mat-list-option>
    </mat-selection-list>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Peruuta</button>
    <button
      mat-button
      color="primary"
      [disabled]="childrenSelected.length === 0"
      [mat-dialog-close]="restoreChildren()"
    >
      Palauta
    </button>
  </mat-dialog-actions>
</div>
