import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Permission} from '../../../models/permission';
import {PermissionService} from '../../../services/permission.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-permission-table',
  templateUrl: './permission-table.component.html',
  styleUrls: ['./permission-table.component.scss']
})
export class PermissionTableComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  dataSource = new MatTableDataSource<Permission>([]);

  isLoadingResults = true;

  /** Columns that are going to be displayed */
  displayedColumns = ['id', 'name'];

  constructor(private permissionService: PermissionService, private router: Router) {
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    /** Sorting for nested objects */
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name;
        default:
          return item[property];
      }
    };
  }

  ngAfterViewInit() {
    this.permissionService.getAll().then((res: Permission[]) => {
      this.dataSource.data = res;
      this.isLoadingResults = false;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editPermission(permissionId: string) {
    this.router.navigate([`permissions/edit/${permissionId}`]);
  }

}
