import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Address, LocatedEntity } from "src/app/models/entities";
import UpdatedAddressObject from "src/app/models/updated-address-object";
import { TcdApiService } from "src/app/services/tcd-api.service";

@Component({
  selector: "app-edit-address-dialog",
  templateUrl: "./edit-address-dialog.component.html",
  styleUrls: ["./edit-address-dialog.component.scss"],
})
export class EditAddressDialogComponent implements OnInit {

  @Input() addressInput: string = ''
  
  addressValidated: Address = null
  inputChanged = new Subject<string>()
  searchResult: Address[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LocatedEntity,
    private tcdApiService: TcdApiService
  ) {
    this.inputChanged.pipe(debounceTime(500)).subscribe((value) => {
      this.validateAddress(value)
      this.searchAddresses(value)
    });
  }

  addressToString(address: Address) {
    return `${address.street}, ${address.postcode} ${address.city}`
  }

  ngOnInit(): void {
    if (this.data) {
      this.addressInput = this.addressToString(this.data.address)
      this.validateAddress(
        this.addressInput
      );
    }
  }

  validateAddress(address:string) {
    this.addressValidated = null;
    this.tcdApiService.validateAddress(address).then((validatedResult) => {
      this.addressValidated = validatedResult[0];
    });
  }

  searchAddresses = async (address:string) => {
    this.searchResult = [];
    const addresses = await this.tcdApiService.validateAddress(address)
      if (addresses && addresses.length > 0) {
        this.searchResult = addresses
      }
  }

  updateInput(value:string) {
    this.addressInput = value
    if (value.length > 4) {
      this.inputChanged.next(value);
    }
  }

  selectInput(address: Address) {
    this.addressInput = this.addressToString(address)
    this.addressValidated = address
    this.searchResult = []
  }

  getNewAddress() {

    const result:UpdatedAddressObject = {
      oldAddress: this.data.address,
      newAddress: this.addressValidated,
    };

    return result;
  }
}
