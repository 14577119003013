<app-navigation-bar class="no-print">
</app-navigation-bar>

<mat-sidenav-container class="sidenav-container">
  <!-- <mat-sidenav #navigationSidenav [disableClose]="!(isHandset$ | async)"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="isAuthenticated && !(isHandset$ | async)"
               class="sidenav no-print">

    <mat-nav-list *ngIf="isAuthenticated">

      <mat-list-item
          routerLink="jobs"
          routerLinkActive="list-item-active"
          *ngIf="helpers.canAccess('JOB_GETALL')">
        <mat-icon mat-list-icon>how_to_vote</mat-icon>
        <h4 mat-line>{{'nav.jobs' | translate}}</h4>
      </mat-list-item>

      <mat-list-item
          routerLink="users"
          routerLinkActive="list-item-active"
          *ngIf="helpers.canAccess('USER_GETALL')">
        <mat-icon mat-list-icon>person</mat-icon>
        <h4 mat-line>{{'nav.users' | translate}}</h4>
      </mat-list-item>

      <mat-list-item
          routerLink="roles"
          routerLinkActive="list-item-active"
          *ngIf="helpers.canAccess('ROLE_GETALL')">
        <mat-icon mat-list-icon>accessibility_new</mat-icon>
        <h4 mat-line>{{'nav.roles' | translate}}</h4>
      </mat-list-item>

      <mat-list-item
          routerLink="permissions"
          routerLinkActive="list-item-active"
          *ngIf="helpers.canAccess('PERMISSION_GETALL')">
        <mat-icon mat-list-icon>pan_tool</mat-icon>
        <h4 mat-line>{{'nav.permissions' | translate}}</h4>
      </mat-list-item>
    </mat-nav-list>

  </mat-sidenav> -->

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>
