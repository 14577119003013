import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Child, PreschoolType } from "src/app/models/entities";
import { PapaParseChildResultObject, PapaParsePreschoolResultObject } from "src/app/models/papaParseObject";
import { CsvDataService } from "src/app/services/csvDataService";
import { RunService } from "src/app/services/run.service";
import { TcdApiService } from "src/app/services/tcd-api.service";

@Component({
  selector: "app-new-optimization",
  templateUrl: "./new-optimization.component.html",
  styleUrls: ["./new-optimization.component.scss"],
})
export class NewOptimizationComponent implements OnInit {

  runName: string = ''

  children: Child[] = []
  preschools: PreschoolType[] = []

  preschoolResourcesData: PapaParsePreschoolResultObject[] = []
  childrenData: PapaParseChildResultObject[] = []

  loadingAddresses: boolean = false;

  constructor(
    public csvDataService: CsvDataService,
    private runService: RunService,
    private router: Router,
    private tcdApiService: TcdApiService
  ) { }

  ngOnInit(): void { }

  uploadPreschoolResources = async ($event: any) => {
    const files = $event.target.files;
    if (files[0]) {
      const publicResourceFile = files[0];
      this.preschoolResourcesData = (await this.csvDataService.parseCsv(publicResourceFile, "iso-8859-1")) as PapaParsePreschoolResultObject[]
    }
  }

  // uploadPrivateResources = async ($event: any) => {
  //   const files = $event.target.files;
  //   if (files[0]) {
  //     const daycareResourceFile = files[0];
  //     this.daycareProvidersResourcesData = (await this.csvDataService.parseCsv(daycareResourceFile, "iso-8859-1")) as PapaParseDaycareResultObject[]
  //   }
  // }

  uploadChildren = async ($event: any) => {
    const files = $event.target.files;
    if (files[0]) {
      const childrenFile = files[0];
      this.childrenData = (await this.csvDataService.parseCsv(childrenFile, "iso-8859-1")) as PapaParseChildResultObject[]
    }
  }

  disableForward = () => {
    return (
      !this.preschoolResourcesData.length ||
      // !this.daycareProvidersResourcesData.length ||
      !this.childrenData.length ||
      this.runName.length === 0
    );
  };

  saveOptimization() {
    const name = this.runName;
    this.validatePreschoolGroupsData().then((source_preschool_groups) => {
      // this.validateDaycareProvidersData().then((source_daycare_providers) => {
        this.validateChildrenData().then((source_children) => {
          
          if (name.length > 0 && source_children && source_preschool_groups) {

            const newRunDraft = {
              name,
              source_children,
              source_preschool_groups,
            };

            this.runService.createOne(newRunDraft).then((createdRunDraft) => {
              this.router.navigate(["optimization", createdRunDraft.id, "draft"]);
              this.loadingAddresses = false;
            });
          } else alert("Optimoinnin tallennus ei onnistunut.");
        });
      // });
    });
  }

  validateChildrenData = async () => {

    const chldr = this.childrenData.map(c => {
      const {
        lapsen_id: id,
        lapsen_etunimi: firstName,
        lapsen_sukunimi: lastName,
        lapsen_syntyma_pvm: birthday,
        lapsen_osoite: street,
        postinumero: postcode,
        toimipaikka: city,
        toimintayksikon_id: currentProviderId,
        toimintayksikon_nimi: currentProviderName,
        lapsen_kieli: language,
      } = c
      const properties = c.ominaisuudet.trim().length > 0 ? c.ominaisuudet.split(',').map(p => p.trim()) : []
      return { id, firstName, lastName, birthday, currentProviderId, currentProviderName, language, properties, address: { street, postcode, city } }
    }) as Child[]

    const addresses = await this.tcdApiService.validateAllAddresses(chldr)

    this.children = chldr.map((child) => {
      
      const address = addresses.find(a => a !== null ? a.entityId === child.id : false)

      child.status = !address || !address.addressUuid ? 'invalid' : ''
      child.address = address
      
      return child
    })

    return this.children
  }

  // validateDaycareProvidersData = async () => {

  //   this.daycareProviders = this.daycareProvidersResourcesData.map(dcp => {
  //     const {
  //       toimintayksikon_id: id,
  //       toimintayksikon_nimi: name,
  //     } = dcp
  //     return { id, name }
  //   })

  //   // const addresses = await this.tcdApiService.validateAllAddresses(daycareProviders)

  //   // this.daycareProviders = addresses.map((address) => {
  //   //   const provider = daycareProviders.find(dcp => dcp.id === address.entityId) as Provider
  //   //   provider.address = address
  //   //   return provider
  //   // })

  //   return this.daycareProviders
  // }

  validatePreschoolGroupsData = async () => {

    const preschools = this.preschoolResourcesData.map(p => {
      const {
        Id: id,
        Nimi: name,
        Lahiosoite: street,
        Postinumero: postcode,
        Postitoimipaikka: city,
        Paikkojen_lkm: capacity,
      } = p

      const properties = p.ominaisuudet.trim().length > 0 ? p.ominaisuudet.split(',').map(p => p.trim()) : []

      return { id, name, capacity: Number(capacity), properties, address: { street, postcode, city } }
    })

    const addresses = await this.tcdApiService.validateAllAddresses(preschools)

    this.preschools = addresses.map((address) => {

      const provider = preschools.find(dcp => dcp.id === address.entityId) as PreschoolType
      provider.address = address

      return provider
    })

    return this.preschools
  }
}
