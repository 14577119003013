import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Helpers} from '../../services/helpers';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  constructor(private router: Router, public helpers: Helpers, public translate: TranslateService) {

  }

  onNew(): void {
    this.router.navigate(['permissions/new']);
  }

  ngOnInit(): void {
    if (!this.helpers.canAccess('PERMISSION_GETALL')) {
      this.router.navigate(['']);
    }
  }

}
