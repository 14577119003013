import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommandBarComponent} from './components/command-bar/command-bar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {NavComponent} from './components/nav/nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {NavigationBarComponent} from './components/navigation-bar/navigation-bar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MatCardModule} from '@angular/material/card';
import {LayoutModule} from '@angular/cdk/layout';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {LoginComponent} from './components/login/login.component';
import {AuthGuardService} from './services/auth-guard.service';
import {AuthService} from './services/auth.service';
import {RoleTableComponent} from './components/roles/role-table/role-table.component';
import {PermissionTableComponent} from './components/permissions/permission-table/permission-table.component';
import {RoleWizardComponent} from './components/role-wizard/role-wizard.component';
import {PermissionWizardComponent} from './components/permission-wizard/permission-wizard.component';
import {RolesComponent} from './components/roles/roles.component';
import {PermissionsComponent} from './components/permissions/permissions.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UsersComponent} from './components/users/users.component';
import {UserWizardComponent} from './components/user-wizard/user-wizard.component';
import {UsersTableComponent} from './components/users/users-table/users-table.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { FrontPageComponent } from './components/frontpage/front-page.component';
import {Helpers} from './services/helpers';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {PaginatorIntlService} from './services/PaginatorIntlService';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatBadgeModule} from '@angular/material/badge';
import {ToastrModule} from 'ngx-toastr';
import {JobWizardComponent} from './components/job-wizard/job-wizard.component';
import {JobsComponent} from './components/jobs/jobs.component';
import {JobsTableComponent} from './components/jobs/jobs-table/jobs-table.component';
import {JobRowsTableComponent} from './components/job-rows/job-rows-table/job-rows-table.component';
import {JobRowsComponent} from './components/job-rows/job-rows.component';
import {JobRowWizardComponent} from './components/job-row-wizard/job-row-wizard.component';
// import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ButtonComponent } from './components/button/button.component';
import { NewOptimizationComponent } from './components/new-optimization/new-optimization.component';
import { BadgeComponent } from './components/badge/badge.component';
import { NewOptimizationTableComponent } from './components/new-optimization/new-optimization-table/new-optimization-table.component';
import { NewOptimizationSidebarComponent } from './components/new-optimization/new-optimization-sidebar/new-optimization-sidebar.component';
import { NewOptimizationBottombarComponent } from './components/new-optimization/new-optimization-bottombar/new-optimization-bottombar.component';
import { UpdateBoxComponent } from './components/new-optimization/new-optimization-table//update-box/update-box.component';
import { InvalidChildrenDialogComponent } from './components/new-optimization/invalid-children-dialog/invalid-children-dialog.component';
import { SharedModule } from './components/shared/shared.module';
import { CompleteOptimizationComponent } from './components/complete-optimization/complete-optimization.component';
import { CompleteOptimizationTableComponent } from './components/complete-optimization/complete-optimization-table/complete-optimization-table.component';
import { CompleteOptimizationSidebarComponent } from './components/complete-optimization/complete-optimization-sidebar/complete-optimization-sidebar.component';
import { ModalChangePreschoolComponent } from './components/complete-optimization/modal-change-preschool/modal-change-preschool.component';
import { CompleteOptimizationBottombarComponent } from './components/complete-optimization/complete-optimization-bottombar/complete-optimization-bottombar.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    NavigationBarComponent,
    CommandBarComponent,
    LoginComponent,
    RoleTableComponent,
    PermissionTableComponent,
    JobsTableComponent,
    JobRowsTableComponent,
    RoleWizardComponent,
    PermissionWizardComponent,
    JobWizardComponent,
    JobRowWizardComponent,
    RolesComponent,
    PermissionsComponent,
    JobsComponent,
    JobRowsComponent,
    UsersComponent,
    UserWizardComponent,
    UsersTableComponent,
    FrontPageComponent,
    ButtonComponent,
    NewOptimizationComponent,
    BadgeComponent,
    NewOptimizationTableComponent,
    NewOptimizationSidebarComponent,
    NewOptimizationBottombarComponent,
    UpdateBoxComponent,
    InvalidChildrenDialogComponent,
    CompleteOptimizationComponent,
    CompleteOptimizationTableComponent,
    CompleteOptimizationSidebarComponent,
    ModalChangePreschoolComponent,
    CompleteOptimizationBottombarComponent
  ],
  imports: [
    SharedModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatChipsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatCardModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatCheckboxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatTooltipModule,
    MatDialogModule,
    ToastrModule.forRoot(),
    MatProgressBarModule
  ],
  providers: [
    AuthGuardService,
    AuthService,
    Helpers,
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    { provide: MAT_DATE_LOCALE, useValue: 'fi-FI' },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
