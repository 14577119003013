import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { PreschoolType } from "src/app/models/entities";
import Optimization from "src/app/models/optimization";
import { RunService } from "src/app/services/run.service";

@Component({
  selector: "app-complete-optimization-sidebar",
  templateUrl: "./complete-optimization-sidebar.component.html",
  styleUrls: ["./complete-optimization-sidebar.component.scss"],
})
export class CompleteOptimizationSidebarComponent implements OnInit {
  optimizationSubscription: Subscription;
  optimizationData: Optimization;
  preschoolSubscription: Subscription;
  preschoolData: PreschoolType[] = [];
  isLoading: boolean = true;

  constructor(private runService: RunService) {
    // Listen for optimization data
    this.optimizationSubscription = this.runService.optimizationObservable.subscribe((optimization) => {
      this.optimizationData = optimization;
      if (this.isLoading) this.isLoading = false;
    });

    // Listen for preschool data
    this.preschoolSubscription = this.runService.preschoolObservable.subscribe((preschools) => {
      this.preschoolData = preschools.sort((school1, school2) => school1.name.localeCompare(school2.name)) as PreschoolType[]
    });
  }

  ngOnInit(): void {}

  countChildrenInPreschool(preschoolId: string) {
    const childrenInPreschool = this.optimizationData.solution.entity_list.filter(
      (child) => child.group === preschoolId && !child.deleted
    );
    return childrenInPreschool.length;
  }

  parsePreschoolAddress(preschoolId:string) {
    const { street, postcode, city } = this.preschoolData.find((preschool) => preschool.id === preschoolId).address
    return `${street}, ${postcode} ${city}`
  }
}
