import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { Child, PreschoolType } from "src/app/models/entities";
import UpdatedAddressObject from "src/app/models/updated-address-object";
import { RunService } from "src/app/services/run.service";
import { EditAddressDialogComponent } from "../../shared/edit-address-dialog/edit-address-dialog.component";

@Component({
  selector: "app-new-optimization-sidebar",
  templateUrl: "./new-optimization-sidebar.component.html",
  styleUrls: ["./new-optimization-sidebar.component.scss"],
})
export class NewOptimizationSidebarComponent implements OnInit, OnDestroy {
  
  @ViewChild("tabs", { static: false }) tabs;

  children: Child[] = [];
  childrenSubscription: Subscription;
  preschoolGroups: PreschoolType[] = [];
  preschoolSubscription: Subscription;
  daycareSubscription: Subscription;

  TotalPlaces: any;

  constructor(private runService: RunService, public dialog: MatDialog) {
    this.childrenSubscription = this.runService.childrenObservable.subscribe((children) => {
      this.children = children;
    });
    this.preschoolSubscription = this.runService.preschoolObservable.subscribe((preschoolGroups) => {
      this.preschoolGroups = preschoolGroups;
      this.tabs.realignInkBar();
    });
  }

  ngOnInit(): void {}

  countTotalPlaces() {
    const places = this.preschoolGroups.map((group) => Number(group.capacity));
    const placesTotal = places.reduce((a, b) => a + b, 0);
    if (placesTotal > 0) return placesTotal;
    else return "-";
  }

  editPreschoolAddress(group:PreschoolType) {

    const dialogRef = this.dialog.open(EditAddressDialogComponent, {
      data: group
    })

    dialogRef.afterClosed().subscribe((result: UpdatedAddressObject) => {

      const updatedPreschools = this.preschoolGroups.map((group) => {
        if (group.id === result.oldAddress.entityId) {
          group.address = result.newAddress
        }
        return group;
      })

      // const updatedDaycares = this.daycareGroups.map((group) => {
      //   if (group.id === result.oldAddress.entityId) {
      //     group.address = result.newAddress
      //   }
      //   return group;
      // })


      updatedPreschools.length ?? this.runService.updateSourcePreschoolGroups(this.runService.runId, updatedPreschools);
      // updatedDaycares.length ?? this.runService.updateSourceDaycareProviders(this.runService.runId, updatedDaycares);
    })
  }

  // editdaycareAddress(group:Provider) {

  //   const dialogRef = this.dialog.open(EditAddressDialogComponent, {
  //     data: group,
  //   })

  //   dialogRef.afterClosed().subscribe((result: UpdatedAddressObject) => {

  //     const updatedDaycares = this.daycareGroups.map((group) => {
  //       if (group.id === result.oldAddress.entityId) {
  //         group.address = result.newAddress
  //       }
  //       return group;
  //     });
  //     this.runService.updateSourceDaycareProviders(this.runService.runId, updatedDaycares);
  //   });
  // }

  ngOnDestroy() {
    this.preschoolSubscription.unsubscribe();
    this.childrenSubscription.unsubscribe();
  }
}
