<h1 mat-dialog-title>Lisää puhelinnumero</h1>
<div mat-dialog-content>
    <p>Lisää puhelinnumero. Voit lisätä joko molempien huoltajien puhelinnumerot, tai vain toisen.</p>
    <mat-form-field appearance="fill">
        <mat-label>Huoltaja 1 matkapuhelin</mat-label>
        <input matInput         [ngModel]="phoneInput"
        (ngModelChange)="updateInput($event)">
    </mat-form-field>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Peruuta</button>
    <button
      mat-button
      [mat-dialog-close]="getNewPhone()"
      [disabled]="!validationService.phoneValidated"
    >
      Hyväksy
    </button>
  </mat-dialog-actions>