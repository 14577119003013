import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Permission} from '../../models/permission';
import {PermissionService} from '../../services/permission.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {Helpers} from '../../services/helpers';

@Component({
  selector: 'app-permission-wizard',
  templateUrl: './permission-wizard.component.html',
  styleUrls: ['./permission-wizard.component.scss']
})
export class PermissionWizardComponent implements OnInit {

  formGroup: FormGroup;
  permission: Permission;
  isLoading = true;
  saveFailed = false;

  constructor(private permissionService: PermissionService,
              private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              public helpers: Helpers,
              private location: Location) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.fetchInitialData(Number(id)).then(async _ => {
        await this.createForm();
        this.isLoading = false;
      });
    });
  }

  async fetchInitialData(id: number): Promise<void> {
    const promises: Promise<any>[] = [];

    if (id) {
      const permission = this.permissionService.getOne(Number(id)).then((res: Permission) => {
        this.permission = res;
      });

      promises.push(...[permission]);
    }
    await Promise.all(promises);
  }

  async createForm(): Promise<void> {
    this.formGroup = this.formBuilder.group({
      name: [this.permission?.name, Validators.required],
    });

    if (!this.helpers.canAccess('PERMISSION_MODIFY')) {
      this.formGroup.disable();
    }
  }

  async onSave(): Promise<void> {
    /** If permission exists, then update, otherwise create new */
    if (this.permission) {
      // Merge permission objects
      const permission = {...this.permission, ...this.formGroup.value};
      const permissionUpdate = this.permissionService.update(permission);

      await Promise.all([permissionUpdate])
        .then(_ => this.location.back())
        .catch(_ => this.saveFailed = true);

    } else {
      const permission = this.formGroup.value;
      this.permissionService.create(permission)
        .then(_ => this.location.back())
        .catch(_ => this.saveFailed = true);
    }
  }

  onClose(): void {
    this.location.back();
  }

  onDelete() {
    if (confirm('Oletko varma?')) {
      this.permissionService.deleteOne(this.permission.id).then(() => {
        this.location.back();
      });
    }
  }

}
