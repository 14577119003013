<div class="container-fluid">
  <div class="flex align-items-end space-between my-3">
    <mat-card-title>{{ "dashboard.title" | translate }}</mat-card-title>
    <app-button color="primary" text="Uusi optimointi" routerLink="optimization/new"></app-button>
  </div>

  <mat-card-content>
    <div class="mat-elevation-z1">
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "dashboard.name" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "dashboard.date_created" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ parseDate(element.created) }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>{{ "dashboard.run_status" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list aria-label="Optimization status">
              <mat-chip *ngIf="!element.optimization_id">Luonnos</mat-chip>
              <mat-chip
                *ngIf="element.optimization_id && optimizationStatus(element.id) === 'FINISHED'"
                class="bg-success"
                >Valmistunut</mat-chip
              >
              <mat-chip
                *ngIf="element.optimization_id && optimizationStatus(element.id) !== 'FINISHED'"
                class="bg-warning"
                >Vaiheessa</mat-chip
              >
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "dashboard.actions" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button mat-stroked-button color="warn" (click)="deleteOptimization(element)">
              {{ "action.delete" | translate }}
            </button>
            <button
              *ngIf="!element['optimization_id']"
              mat-flat-button
              color="primary"
              routerLink="optimization/{{ element.id }}/draft"
            >
              <!-- {{ "action.open" | translate }} -->
              Avaa luonnos
            </button>
            <button
              *ngIf="element['optimization_id']"
              [disabled]="element.optimization_id && optimizationStatus(element.id) !== 'FINISHED'"
              mat-flat-button
              color="primary"
              routerLink="optimization/{{ element.id }}/complete"
            >
              Avaa optimointi
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-card *ngIf="isLoadingResults" class="loading-indicator">
        <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
      </mat-card>
    </div>
  </mat-card-content>
</div>
