<!-- <div *ngIf="loadingData" class="container-fluid py-3">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div> -->

<!-- <div
  class="flex-column d-none"
  style="height: calc(100vh - 80px)"
  [ngClass]="{ 'd-none': loadingData, 'd-flex': !loadingData }"
> -->
<div class="flex-column d-flex" style="height: calc(100vh - 80px)">
  <div class="row m-0 overflow-auto">
    <div class="col col-sm-6 col-md-7 col-lg-8 col-xl-9">
      <div>
        <app-complete-optimization-table></app-complete-optimization-table>
      </div>
    </div>
    <div class="col col-sm-6 col-md-5 col-lg-4 col-xl-3">
      <app-complete-optimization-sidebar></app-complete-optimization-sidebar>
    </div>
  </div>

  <app-complete-optimization-bottombar></app-complete-optimization-bottombar>
</div>
