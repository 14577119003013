import {HttpHeaders} from '@angular/common/http';

export class Helpers {
  static getToken() {
    return {
      headers: new HttpHeaders({
        Authorization: 'Token ' + localStorage.getItem('token')
      })
    };
  }

  public canAccess(requiredPermission: string) {
    const permissions = localStorage.getItem('permissions');
    return permissions && permissions.indexOf(requiredPermission) > -1;
  }
}
