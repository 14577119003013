<mat-progress-spinner color="primary" *ngIf="isLoading" mode="indeterminate" class="m-3"> </mat-progress-spinner>

<!-- <div *ngIf="!isLoading"> -->
<div [ngClass]="{ 'opacity-0': isLoading }">
  <!-- <div class="opacity-0"> -->
  <h2 class="my-2">{{ runName }}</h2>

  <div class="d-flex justify-content-between align-items-center">
    <mat-form-field appearance="fill" class="flex-grow-1">
      <mat-label>Vapaakenttäsuodatus</mat-label>
      <input matInput type="text" [(ngModel)]="searchQuery" (ngModelChange)="handleSearchFilter($event)" />
      <button
        *ngIf="searchQuery.length > 0"
        matSuffix
        mat-icon-button
        aria-label="Clear search"
        (click)="handleSearchFilter('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="fill" class="ml-2">
      <mat-label>Esiopetuspaikka</mat-label>
      <mat-select [(ngModel)]="selectedPreschoolGroups" (ngModelChange)="handlePreschoolInput()">
        <mat-option [value]="null"><i>Näytä kaikki</i> </mat-option>
        <mat-option *ngFor="let preschool of preshoolSelect" [value]="preschool['ids']">{{
          preschool["name"]
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mat-elevation-z1">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Lapsen nimi </mat-header-cell>
        <!-- <mat-cell *matCellDef="let row"> {{ parseName(row["id"]) }} </mat-cell> -->
        <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="esiopetus paikka">
        <mat-header-cell *matHeaderCellDef> Esiopetuspaikka </mat-header-cell>
        <mat-cell *matCellDef="let row" class="d-flex justify-content-between align-items-center">
          <!-- style="text-align: left; white-space: pre-line" -->
          <button
            mat-stroked-button
            style="text-align: left; white-space: pre-line"
            (click)="changePreschool(row)"
            [disabled]="optimizationStatus === 'complete' || isRefreshing"
          >
            <small class="d-inline-block" style="line-height: 1.25rem">
              {{ parsePreschool(row.group) }}
            </small>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nykyinen päivähoitoyksikkö">
        <mat-header-cell *matHeaderCellDef> Nykyinen päivähoitoyksikkö </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ parseDaycare(row.id) }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="varhaiskasvatusalue">
        <mat-header-cell *matHeaderCellDef> Oppilasalue </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ parseArea(row["id"]) }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="info">
        <mat-header-cell *matHeaderCellDef> Info </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button [disabled]="optimizationStatus === 'complete' || isRefreshing">
            <mat-icon aria-label="Poista lapsi" (click)="deleteItem(row['id'])">delete</mat-icon>
          </button>
          <!-- <mat-chip class="mr-1" *ngIf="row['properties'].includes('kielikylpy')" selected>K</mat-chip>
          <mat-chip class="mr-1" *ngIf="row['properties'].includes('vuorohoito')" selected color="accent">V</mat-chip> -->
          <mat-chip class="mr-1" *ngIf="[].includes('kielikylpy')" selected>K</mat-chip>
          <mat-chip class="mr-1" *ngIf="[].includes('vuorohoito')" selected color="accent">V</mat-chip>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator
      [length]="dataSource.data.length"
      [pageIndex]="0"
      [pageSizeOptions]="[25, 50, 100, 250]"
      [pageSize]="100"
    >
      ></mat-paginator
    >
  </div>
</div>
