import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Child, PreschoolType } from "src/app/models/entities";
import { RunService } from "src/app/services/run.service";
import { DeletedChildrenDialogComponent } from "../../shared/deleted-children-dialog/deleted-children-dialog.component";
import { InvalidChildrenDialogComponent } from "../invalid-children-dialog/invalid-children-dialog.component";

@Component({
  selector: "app-new-optimization-bottombar",
  templateUrl: "./new-optimization-bottombar.component.html",
  styleUrls: ["./new-optimization-bottombar.component.scss"],
})
export class NewOptimizationBottombarComponent implements OnInit, OnDestroy {

  runIdSubscription: Subscription;
  childrenSubscription: Subscription;
  daycareSubscription: Subscription;
  preschoolSubscription: Subscription;

  startButtonDisabled: boolean = true;
  runId: number;
  childrenAll: Child[] = [];
  childrenDeleted: Child[] = [];
  childrenInvalid: Child[] = [];
  preschoolGroups: PreschoolType[] = [];

  constructor(
    private runService: RunService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.runIdSubscription = this.route.params.subscribe((params) => {
      if (params["runId"]) this.runId = params["runId"];
    });

    this.childrenSubscription = this.runService.childrenObservable.subscribe((children) => {
      this.childrenAll = children;
      this.childrenDeleted = children.filter((child) => child.status === "deleted");
      this.childrenInvalid = children.filter((child) => child.status === "invalid");
      this.isStartButtonDisabled();
    });
    this.preschoolSubscription = this.runService.preschoolObservable.subscribe((groups) => {
      this.preschoolGroups = groups;
      this.isStartButtonDisabled();
    });
  }

  ngOnInit(): void {}

  openDeletedChildrenDialog() {
    const deletedChildrenDialog = this.dialog.open(DeletedChildrenDialogComponent, {
      data: { childrenDeleted: this.childrenDeleted },
    });
    deletedChildrenDialog.afterClosed().subscribe((childrenToRestore: string[]) => {

      if (childrenToRestore && childrenToRestore.length > 0 && this.runId) {

        const childrenToRestoreIds = childrenToRestore;
        // const childrenAllCopy = JSON.parse(JSON.stringify(this.childrenAll));
        
        const childrenUpdated = this.childrenAll.map((child) => {
          const childId = child["id"];
          if (childrenToRestoreIds.includes(childId)) {
            child["status"] = "";
            return child;
          } else return child;
        });

        this.runService.updateSourceChildren(this.runId, childrenUpdated);
      } else console.log("No children provided to restore");
    });
  }

  openInvalidChildrenDialog() {
    const invalidChildrenDialog = this.dialog.open(InvalidChildrenDialogComponent, {
      data: { childrenInvalid: this.childrenInvalid },
    });
    invalidChildrenDialog.afterClosed().subscribe((children: string[]) => {});
  }

  startRun() {

    const runConfirmed = confirm(
      "Aloitetaanko ajo? Aloituksen jälkeen tietoja ei voi enää muokata. Ajo kestää noin 4 tuntia."
    );

    if (runConfirmed) {
      
        // const children: object[] = this.childrenAll
        //   .filter((child) => child["status"] === "")
        //   .map((child) => {
        //     const daycare = this.daycareProviders.find((daycare) => daycare.name === child.currentProvider);
        //     let elem = {
        //       id: child.id,
        //       addressUuid: child.address.addressUuid,
        //       preschoolGroup: null,
        //       pinned: false,
        //       daycareProvider: daycare ? daycare.id : null,
        //       properties: [],
        //     };
        //     // if (child["kielikylvyn ryhmä"] && child["kielikylvyn ryhmä"].length > 1) elem.properties.push("kielikylpy");
        //     // if (child["vuorohoidon tarve"] && child["vuorohoidon tarve"].length > 1) elem.properties.push("vuorohoito");
        //     return elem;
        //   });

        // const daycareProviders = this.daycareProviders.map((daycare) => ({
        //   id: daycare.id,
        //   name: daycare.name,
        //   addressUuid: daycare.address.addressUuid,
        // }));

        // const preschoolGroups = this.preschoolGroups.map((school) => {
        //   const elem = {
        //     id: school.id,
        //     name: school.name,
        //     addressUuid: school.address.addressUuid,
        //     capacity: parseInt(school["Paikkojen määrä"]),
        //     // school: school["Koulun yhteydessä"].toLowerCase() === "kyllä" ? true : false,
        //     // private: school["private"],
        //     properties: [],
        //   };
        //   // if (school["kielikylpy"] && school["kielikylpy"].length > 2) elem.properties.push("kielikylpy");
        //   // if (school["vuorohoito"] && school["vuorohoito"].length > 2) elem.properties.push("vuorohoito");
        //   return elem;
        // });

        // const optimization = { children, daycareProviders, preschoolGroups };
        const optimization = { children: this.childrenAll, preschoolGroups: this.preschoolGroups };

        // console.log(this.runService.children);
        // console.log(optimization);
        this.runService.startRun(this.runId, optimization).then((optimizationId) => {
          if (optimizationId) this.router.navigate([""]);
          else alert("Ajon aloitus ei onnistunut.");
        });
    }
  }

  isStartButtonDisabled() {

    const isDisabled =
      this.childrenAll.filter(({ status }) => status !== 'deleted').filter(({ address }) => !address || !address.addressUuid).length
      // || !!this.daycareProviders.filter(({ address }) => !address || !address.addressUuid).length
      || !!this.preschoolGroups.filter(({ address }) => !address || !address.addressUuid).length

    this.startButtonDisabled = isDisabled as boolean
  }

  ngOnDestroy() {
    this.childrenSubscription.unsubscribe();
    this.runIdSubscription.unsubscribe();
  }
}
