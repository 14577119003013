<mat-tab-group mat-align-tabs="center">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>person</mat-icon>
      {{'user.singleTitle' | translate}}
    </ng-template>

    <ng-container *ngIf="!isLoading">
      <app-command-bar>
        <button (click)="onSave()" [disabled]="!formGroup.valid || !helpers.canAccess('USER_MODIFY')" mat-button>
          <mat-icon>save</mat-icon>
          {{'buttons.save' | translate}}
        </button>

        <button (click)="onClose()" mat-button>
          <mat-icon>cancel</mat-icon>
          {{'buttons.close' | translate}}
        </button>

      </app-command-bar>

      <div [formGroup]="formGroup" class="container-fluid">
        <mat-card>
          <mat-card-title>{{'user.userInformation' | translate}}</mat-card-title>

          <mat-card-content>

            <div class="row">
              <div class="col">
                <mat-form-field class="form-element">
                  <input formControlName="username" matInput placeholder="{{'user.username' | translate}}">
                  <mat-error *ngIf="!formGroup.controls['username'].valid && formGroup.controls['username'].touched">
                    {{'miscellaneous.required' | translate}}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="form-element">
                  <input formControlName="password" matInput minlength="6" placeholder="{{'user.password' | translate}}"
                         type="password">
                  <mat-error *ngIf="!formGroup.controls['password'].valid && formGroup.controls['password'].touched">
                    {{'miscellaneous.required' | translate}}
                  </mat-error>
                  <mat-error *ngIf="formGroup.controls.password.errors?.noMatch">
                    {{'miscellaneous.needsToMatch' | translate}}
                  </mat-error>
                  <mat-error
                      *ngIf="!formGroup.controls['password'].valid && formGroup.controls['password'].touched && formGroup.controls.password.errors?.minlength">
                    {{'miscellaneous.passwordMinLength' | translate}}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="form-element">
                  <input formControlName="passwordAgain" matInput placeholder="{{'user.passwordAgain' | translate}}"
                         type="password">
                  <mat-error
                      *ngIf="!formGroup.controls['passwordAgain'].valid && formGroup.controls['passwordAgain'].touched">
                    {{'miscellaneous.required' | translate}}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="form-element">
                  <input formControlName="firstName" matInput placeholder="{{'user.firstname' | translate}}">
                  <mat-error *ngIf="!formGroup.controls['firstName'].valid && formGroup.controls['firstName'].touched">
                    {{'miscellaneous.required' | translate}}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="form-element">
                  <input formControlName="lastName" matInput placeholder="{{'user.lastname' | translate}}">
                  <mat-error *ngIf="!formGroup.controls['lastName'].valid && formGroup.controls['lastName'].touched">
                    {{'miscellaneous.required' | translate}}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>{{'user.role' | translate}}</mat-label>
                  <mat-select formControlName="role_id">
                    <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>{{'user.active' | translate}}</mat-label>
                  <mat-select formControlName="active">
                    <mat-option [value]="0">{{'miscellaneous.no' | translate}}</mat-option>
                    <mat-option [value]="1">{{'miscellaneous.yes' | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="form-element">
                  <input formControlName="email" matInput placeholder="{{'user.email' | translate}}">
                  <mat-error *ngIf="!formGroup.controls['email'].valid && formGroup.controls['email'].touched">
                    {{'miscellaneous.required' | translate}}
                  </mat-error>
                </mat-form-field>

              </div>

            </div>

          </mat-card-content>
        </mat-card>

      </div>
    </ng-container>

    <mat-card *ngIf="isLoading" class="loading-indicator">
      <mat-progress-spinner
          color="primary"
          mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>

  </mat-tab>

</mat-tab-group>


