import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./services/auth-guard.service";
import { LoginComponent } from "./components/login/login.component";
import { RolesComponent } from "./components/roles/roles.component";
import { RoleWizardComponent } from "./components/role-wizard/role-wizard.component";
import { PermissionsComponent } from "./components/permissions/permissions.component";
import { PermissionWizardComponent } from "./components/permission-wizard/permission-wizard.component";
import { UsersComponent } from "./components/users/users.component";
import { UserWizardComponent } from "./components/user-wizard/user-wizard.component";
import { FrontPageComponent } from "./components/frontpage/front-page.component";
import { NewOptimizationComponent } from "./components/new-optimization/new-optimization.component";
import { NewOptimizationTableComponent } from "./components/new-optimization/new-optimization-table/new-optimization-table.component";
import { CompleteOptimizationComponent } from "./components/complete-optimization/complete-optimization.component";

const routes: Routes = [
  { path: "", component: FrontPageComponent, canActivate: [AuthGuardService] },
  { path: "roles", component: RolesComponent, canActivate: [AuthGuardService] },
  { path: "roles/new", component: RoleWizardComponent, canActivate: [AuthGuardService] },
  { path: "roles/edit/:id", component: RoleWizardComponent, canActivate: [AuthGuardService] },
  { path: "permissions", component: PermissionsComponent, canActivate: [AuthGuardService] },
  { path: "permissions/new", component: PermissionWizardComponent, canActivate: [AuthGuardService] },
  { path: "permissions/edit/:id", component: PermissionWizardComponent, canActivate: [AuthGuardService] },
  { path: "users", component: UsersComponent, canActivate: [AuthGuardService] },
  { path: "users/new", component: UserWizardComponent, canActivate: [AuthGuardService] },
  { path: "users/edit/:id", component: UserWizardComponent, canActivate: [AuthGuardService] },
  { path: "login", component: LoginComponent },
  { path: "optimization/new", component: NewOptimizationComponent, canActivate: [AuthGuardService] },
  { path: "optimization/:runId/draft", component: NewOptimizationTableComponent, canActivate: [AuthGuardService] },
  { path: "optimization/:runId/complete", component: CompleteOptimizationComponent, canActivate: [AuthGuardService] },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
