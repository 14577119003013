import { Injectable } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { RunService } from "./run.service";
import { TcdApiService  } from "./tcd-api.service";

@Injectable({
    providedIn: 'root'
  })

  export class ValidationService {
    dataSource = new MatTableDataSource<any>([]);
    unfilteredChildren: object[] = [];
    filteredChildren: object[] = [];
    filterUncomplete: boolean = false;
    loadingData: boolean = true;
    phoneValidated: object = null;

      constructor(public runService: RunService, public tcdApiService: TcdApiService) {}


      public validatePhone(val) {
        const regEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            if (regEx.test(val)) {
            this.phoneValidated = val;
          } else {
              return null;
          }
        }

  }