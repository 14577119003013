import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import Fuse from "fuse.js";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Child } from "src/app/models/entities";
import Optimization, { OptimizationChild } from "src/app/models/optimization";
import { Run } from "src/app/models/run";
import { RunService } from "src/app/services/run.service";
import { ModalChangePreschoolComponent } from "../modal-change-preschool/modal-change-preschool.component";

const fuseOptions = {
  threshold: 0.2,
  minMatchCharLength: 3,
  keys: ["lastName", "firstName", "address.schoolDistrict.name", "currentProvider", "toimipaikka"],
};

@Component({
  selector: "app-complete-optimization-table",
  templateUrl: "./complete-optimization-table.component.html",
  styleUrls: ["./complete-optimization-table.component.scss"],
})
export class CompleteOptimizationTableComponent implements OnInit, AfterViewInit, OnDestroy {
  runId: number;
  runName: string = "";
  searchQuery: string = "";
  filterActive: boolean = false;
  fuse: any;
  searchQueryListener = new Subject<string>();
  childrenSubscription: Subscription;
  childrenData: Child[] = [];
  childrenDataFiltered: object[] = [];
  optimizationSubscription: Subscription;
  optimizationData: Optimization;
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns = ["id", "esiopetus paikka", "nykyinen päivähoitoyksikkö", "varhaiskasvatusalue", "info"];
  optimizationStatus: string | null = null;
  optimizationStatusSubscription: Subscription;
  preshoolSelect: { name: string; ids: string[] }[] = [];
  selectedPreschoolGroups: string[] | null = null;
  isLoading: boolean = true;
  isRefreshing: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private runService: RunService, private route: ActivatedRoute, private dialog: MatDialog) {
    //Listen for run ID
    this.route.params.subscribe((params) => {
      this.runId = params["runId"];
      this.runService.getOne(this.runId).then((run: Run) => {
        if (run) {
          this.runName = run.name;
          this.runService.startUpdateInterval();
        }
      });
    });

    // Listen for children data
    this.childrenSubscription = this.runService.childrenObservable.subscribe((children) => {
      this.childrenData = children;
      this.childrenDataFiltered = children;
      this.handleSearchFilter("");
    });

    // Listen for optimization data
    this.optimizationSubscription = this.runService.optimizationObservable.subscribe((optimization) => {
      this.optimizationData = optimization;
      // this.dataSource.data = optimization.children;
      this.filterViewData();
      this.parsePreschoolInputOptions();
      if (this.isLoading) this.isLoading = false;
    });

    // Perform search on text input
    this.searchQueryListener.pipe(debounceTime(500)).subscribe(() => {
      this.filterViewData();
    });

    // Listen for optimization status
    this.optimizationStatusSubscription = this.runService.optimizationStateObservable.subscribe(
      (optimizationStatus) => {
        this.handleSearchFilter("");
        this.optimizationStatus = optimizationStatus;
      }
    );
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  parsePreschoolInputOptions() {
    if (this.optimizationData && this.optimizationData.solution.group_list.length > 0) {
      const schoolNames = this.optimizationData.solution.group_list.map((preschool) => preschool.name.split(", ")[0]);
      const schoolNamesUnique: string[] = [...new Set(schoolNames)];
      const parsedData = schoolNamesUnique.map((school) => ({
        name: school,
        ids: this.optimizationData.solution.group_list
          .filter((group) => group.name.includes(school))
          .map((group) => group.id),
      }));
      this.preshoolSelect = parsedData;
    }
  }

  deleteItem(childId: string) {
    this.isRefreshing = true;
    this.runService.optimizationChildDeletedStatus(this.runId, childId, true).then((result) => {
      this.isRefreshing = false;
      if (result.successful) {
        let optimizationUpdated: Optimization = JSON.parse(JSON.stringify(this.optimizationData));
        optimizationUpdated.solution.entity_list = optimizationUpdated.solution.entity_list.map((child) => {
          if (child.id === childId) child.deleted = true;
          return child;
        });
        this.runService.optimization = optimizationUpdated;
        this.runService.optimizationObservable.next(optimizationUpdated);
      } else console.log("UPDATE FAILED");
    });
  }

  handleSearchFilter(textInput: string) {
    this.searchQuery = textInput;
    this.searchQueryListener.next();
  }

  handlePreschoolInput() {
    this.searchQueryListener.next();
  }

  filterViewData() {

    let optimizationChildren: OptimizationChild[] = JSON.parse(JSON.stringify(this.optimizationData.solution.entity_list));

    // Filter deleted children
    optimizationChildren = optimizationChildren.filter((child) => !child.deleted);

    // Filter by preschool groups
    if (this.selectedPreschoolGroups && this.selectedPreschoolGroups.length > 0) {
      optimizationChildren = optimizationChildren.filter((child) =>
        this.selectedPreschoolGroups.includes(child.group)
      );
    }

    // Filter by search
    if (this.searchQuery.length > 2) {
      const fuse = new Fuse(this.childrenData, fuseOptions);
      const fuseResult = fuse.search(this.searchQuery);
      const childIds: string[] = fuseResult.map((result) => result["item"]["id"]);
      optimizationChildren = optimizationChildren.filter((child) => childIds.includes(child["id"])).sort((child1, child2) => child1.name.localeCompare(child2.name))
    }
    
    this.dataSource.data = optimizationChildren;
  }

  parseName(childId:string) {
    const child = this.childrenData.find((child) => child["id"] === childId);
    if (child) return child["lapsen nimi"];
    else return "";
  }

  parsePreschool(preschoolId: string) {
    const preschool = this.optimizationData.solution.group_list.find((preschool) => preschool.id === preschoolId);
    if (preschool) return preschool.name;
    else return "";
  }

  parseDaycare(childId: string) {
    const child = this.childrenData.find((child) => child.id === childId)
    return child.currentProviderName
  }

  parseArea(childId: string) {
    return this.childrenData.find((child) => child.id === childId).address.schoolDistrict.name
  }

  changePreschool(child) {
    const dialogRef = this.dialog.open(ModalChangePreschoolComponent, {
      data: { child, preschools: this.optimizationData.solution.group_list },
    });
    dialogRef.afterClosed().subscribe((output: { childId: string; newPreschoolId: string }) => {
      if (output) {
        let optimizationUpdated: Optimization = JSON.parse(JSON.stringify(this.optimizationData));
        optimizationUpdated.solution.entity_list = optimizationUpdated.solution.entity_list.map((child) => {
          if (child.id === output.childId) child.group = output.newPreschoolId;
          return child;
        });
        this.isRefreshing = true;
        this.runService.updateResult2(this.runId, output.childId, output.newPreschoolId).then((result) => {
          this.isRefreshing = false;
          if (result.successful) {
            this.runService.optimization = optimizationUpdated;
            this.runService.optimizationObservable.next(optimizationUpdated);
          } else console.log("UPDATE FAILED");
        });
        // this.runService.updateResult(this.runId, optimizationUpdated);
      }
    });
  }

  ngOnDestroy() {
    this.childrenSubscription.unsubscribe();
    this.optimizationSubscription.unsubscribe();
    this.runService.stopUpdateInterval();
  }
}
