<mat-toolbar class="command-bar no-print">

  <mat-toolbar-row fxLayout="row wrap">

    <ng-content></ng-content>

  </mat-toolbar-row>

</mat-toolbar>

