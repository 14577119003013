<div *ngIf="loadingData" class="container-fluid py-3">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div [ngClass]="{ 'd-none': loadingData, 'd-flex': !loadingData }" class="flex-column d-none"
  style="height: calc(100vh - 80px)">
  <div class="row m-0 overflow-auto">
    <div class="col col-sm-6 col-md-7 col-lg-8 col-xl-9">
      <div>
        <h2 class="my-2">{{ optimizationName }}</h2>

        <div class="d-flex justify-content-between align-items-center">
          <mat-form-field appearance="fill" class="flex-grow-1 mr-3">
            <mat-label>Vapaakenttäsuodatus</mat-label>
            <input matInput type="text" [(ngModel)]="searchQuery" (ngModelChange)="handleSearchFilter($event)" />
            <button *ngIf="searchQuery.length > 0" matSuffix mat-icon-button aria-label="Clear search"
              (click)="handleSearchFilter('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-slide-toggle [(ngModel)]="filterActive" (change)="toggleFilterMissingRows($event)">Suodata puuttuvat
            tiedot</mat-slide-toggle>
        </div>

        <div class="mat-elevation-z1">
          <mat-table *ngIf="!loadingData" [dataSource]="dataSource">

            <ng-container matColumnDef="lastName">
              <mat-header-cell *matHeaderCellDef> Lapsen sukunimi </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.lastName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="firstName">
              <mat-header-cell *matHeaderCellDef> Lapsen Etunimi </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.firstName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="birthday">
              <mat-header-cell *matHeaderCellDef> Syntynyt </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.birthday }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="address">
              <mat-header-cell *matHeaderCellDef> Osoite </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="{ 'mat-bg-danger': !row.address || !row.address.addressUuid }"
                (click)="openEditAddressDialog(row)">
                {{ !row.address || row.address.street === '' ? '' : row.address.street + ', ' + row.address.postcode + ' ' + row.address.city }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="currentProvider">
              <mat-header-cell *matHeaderCellDef> Toimintayksikkö </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.currentProviderName }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="schoolDistrict">
              <mat-header-cell *matHeaderCellDef>Oppilaaksiottoalue</mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="{ 'mat-bg-danger': !row.address?.schoolDistrict || !row.address.schoolDistrict.name }">
                {{ row.address?.schoolDistrict?.name }}
              </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="oppilaaksiottoalue">
              <mat-header-cell *matHeaderCellDef> Oppilaaksiottoalue </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row["oppilaaksiottoalue"] }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="info">
              <mat-header-cell *matHeaderCellDef> Info </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-icon class="mr-1" color="{{ hasPhoneNumber(row) ? 'primary' : 'warn' }}">{{
                  hasPhoneNumber(row) ? "phone_enabled" : "phone_disabled"
                  }}</mat-icon>
                <mat-chip class="mr-1" *ngIf="row['kielikylvyn ryhmä'].length > 1" selected>K</mat-chip>
                <mat-chip class="mr-1" *ngIf="row['vuorohoidon tarve'].length > 1" selected color="accent">V</mat-chip>
              </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="delete">
              <mat-header-cell *matHeaderCellDef> Poista lapsi </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <button mat-icon-button>
                  <mat-icon aria-label="Poista lapsi" (click)="deleteItem(row['id'])">delete</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="[25, 50, 100, 250]"
            [pageSize]="100">
            ></mat-paginator>
        </div>
      </div>
    </div>
    <div class="col col-sm-6 col-md-5 col-lg-4 col-xl-3">
      <app-new-optimization-sidebar></app-new-optimization-sidebar>
    </div>
  </div>
  <app-new-optimization-bottombar class="bottombar"></app-new-optimization-bottombar>
</div>