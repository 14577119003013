import { TranslateService } from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material/paginator';

export class PaginatorIntlService extends MatPaginatorIntl {
    translateService: TranslateService;
    itemsPerPageLabel = 'Items per page';
    nextPageLabel     = 'Next page';
    previousPageLabel = 'Previous page';

    getRangeLabel = (page, pageSize, length) => {
        if (length === 0 || pageSize === 0) {
            return '0' + ' - ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex  + ' (' + length + ')';
    }

    injectTranslateService(translate: TranslateService) {
        this.translateService = translate;

        this.translateService.onLangChange.subscribe(() => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    translateLabels() {
        this.itemsPerPageLabel = this.translateService.instant('paginator.items_per_page');
        this.nextPageLabel = this.translateService.instant('paginator.next_page');
        this.previousPageLabel = this.translateService.instant('paginator.previous_page');
    }

}
