<div class="container-fluid">

  <mat-card>
    <mat-card-title>{{'user.title' | translate}}</mat-card-title>

    <mat-card-content>
      <mat-form-field class="full-width-search">
        <mat-label>{{'miscellaneous.search' | translate}}</mat-label>
        <input (keyup)="applyFilter($event)" matInput placeholder="Matti">
      </mat-form-field>

      <div class="mat-elevation-z1">

        <mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'user.id' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'user.name' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.firstName + ' ' + row.lastName}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'user.username' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.username}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'user.email' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.email}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row class="cursor-pointer"  (click)="editUser(row.id)" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator #paginator
                       [length]="dataSource.data.length"
                       [pageIndex]="0"
                       [pageSizeOptions]="[25, 50, 100, 250]"
                       [pageSize]="50">
        </mat-paginator>

        <mat-card *ngIf="isLoadingResults" class="loading-indicator">
          <mat-progress-spinner
              color="primary"
              mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</div>
