import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-modal-change-preschool",
  templateUrl: "./modal-change-preschool.component.html",
  styleUrls: ["./modal-change-preschool.component.scss"],
})
export class ModalChangePreschoolComponent implements OnInit {
  preschoolSelected: number[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { child; preschools }) {}

  ngOnInit(): void {
    this.preschoolSelected.push(this.data.child["preschoolGroup"]);
  }

  generateOutput() {
    if (this.preschoolSelected[0] !== this.data.child["preschoolGroup"]) {
      return {
        childId: this.data.child["id"],
        newPreschoolId: this.preschoolSelected[0],
      };
    } else return null;
  }
}
