<app-command-bar>

  <button (click)="onNew()" mat-button [disabled]="!helpers.canAccess('PERMISSION_MODIFY')">
    <mat-icon>pan_tool</mat-icon>
    {{'permission.newPermission' | translate}}
  </button>

</app-command-bar>

<app-permission-table></app-permission-table>
