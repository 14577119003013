import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EditAddressDialogComponent } from "./edit-address-dialog/edit-address-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { DeletedChildrenDialogComponent } from "./deleted-children-dialog/deleted-children-dialog.component";

@NgModule({
  declarations: [EditAddressDialogComponent, DeletedChildrenDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    FormsModule,
    MatButtonModule,
  ],
  exports: [EditAddressDialogComponent, DeletedChildrenDialogComponent],
})
export class SharedModule {}
