<mat-tab-group [selectedIndex]="0" #tabs>
  <mat-tab label="Esiopetuspaikat">
    <div class="mt-2">
      <p>
        Lapsia yhteensä: <strong>{{ children.length > 0 ? children.length : "-" }}</strong>
      </p>
      <p>
        Paikkoja yhteensä: <strong>{{ countTotalPlaces() }}</strong>
      </p>
    </div>

    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let group of preschoolGroups"
        [ngClass]="{ 'mat-bg-danger': !group.address.addressUuid }"
      >
        <mat-expansion-panel-header>
          <div class="d-flex align-items-center w-100 justify-content-between">
            <mat-panel-title> {{ group.name }} </mat-panel-title>
            <div class="d-flex flex-column mr-1">
              <span
                class="badge badge-dark"
                style="background-color: #ff4081"
                *ngIf="group.properties.includes('SHIFT_CARE')"
                >V</span
              >
              <!-- <span
                class="badge badge-dark"
                style="background-color: #3f51b5"
                *ngIf="group['kielikylpy'] && group['kielikylpy'].length > 2"
                >K</span -->
            </div>
            <mat-panel-description class="flex-grow-0">
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>{{ group.capacity }}</mat-chip>
              </mat-chip-list>
            </mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <p><strong>Osoite: </strong>{{ group.address.street }}, {{ group.address.postcode }} {{ group.address.city }}</p>
        <button *ngIf="!group.address.addressUuid" mat-flat-button (click)="editPreschoolAddress(group)">
          Muokkaa osoitetta
        </button>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-tab>
</mat-tab-group>
