<div class="container-sm p-0">
  <h2 mat-dialog-title>Vaihda esiopetuspaikka</h2>
  <mat-dialog-content class="mat-typography">
    <p>Valitse listasta uusi esiopetuspaikka lapselle.</p>
    <mat-selection-list [multiple]="false" [(ngModel)]="preschoolSelected" class="mt-2 mb-4 mat-elevation-z10">
      <mat-list-option *ngFor="let preschool of data.preschools" [value]="preschool['id']">
        {{ preschool["name"] }}
      </mat-list-option>
    </mat-selection-list>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="border-top">
    <button mat-button mat-dialog-close>Peruuta</button>
    <button
      mat-button
      color="primary"
      [disabled]="preschoolSelected[0] === data.child['preschoolGroup']"
      [mat-dialog-close]="generateOutput()"
    >
      Päivitä
    </button>
  </mat-dialog-actions>
</div>
