<app-command-bar>

  <button (click)="onNew()" mat-button [disabled]="!helpers.canAccess('USER_MODIFY')">
    <mat-icon>person_add</mat-icon>
    {{'user.newUser' | translate}}
  </button>

</app-command-bar>

<app-users-table></app-users-table>
